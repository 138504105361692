// frontend/components/SubscriptionManager.tsx
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Grid,
  Paper,
  Button,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Snackbar,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import axiosInstance from '../utils/axiosConfig';
import { useLocation } from 'react-router-dom';
import { useRemainingQuotes } from '../RemainingQuotesContext';

const stripePromise: Promise<Stripe | null> = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY!
);

interface Plan {
  _id: string;
  name: string;
  quoteLimit: number;
  stripePriceId?: string; // 'Free' plan may not have this
}

interface CompanySubscription {
  planTier: Plan;
  compSettingsSubscriptionStatus: string;
  subscriptionObjStatus: string;
  subscriptionId?: string; 
  usage: {
    quotesGenerated: number;
    month: number;
  };
  currentPeriodEnd: Date;
  subscriptionPlatform?: 'web' | 'ios' | null; 
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const freePlan: Plan = { _id: 'free', name: 'Free', quoteLimit: 15, stripePriceId: undefined };

const SubscriptionManager: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [subscription, setSubscription] = useState<CompanySubscription | null>(null);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({ open: false, message: '', severity: 'success' });
  const [unsubscribeDialogOpen, setUnsubscribeDialogOpen] = useState(false);
  const [processingUnsubscribe, setProcessingUnsubscribe] = useState(false);

  const location = useLocation();
  const { refreshRemainingQuotes } = useRemainingQuotes();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [plansRes, subscriptionRes] = await Promise.all([
          axiosInstance.get('/api/plans'),
          axiosInstance.get('/api/subscription'),
        ]);
        const fetchedPlans: Plan[] = plansRes.data;
        const combinedPlans: Plan[] = [freePlan, ...fetchedPlans];

        const desiredPlanOrder = ['Free', 'Pro', 'Cowboy'];
        combinedPlans.sort((a, b) => {
          return desiredPlanOrder.indexOf(a.name) - desiredPlanOrder.indexOf(b.name);
        });

        setPlans(combinedPlans);
        setSubscription(subscriptionRes.data);


        await refreshRemainingQuotes();
      } catch (error) {
        console.error('Error fetching subscription data:', error);
        setSnackbar({ open: true, message: 'Failed to load subscription data.', severity: 'error' });
      } finally {
        setLoading(false);
      }
    };
    fetchData();

  }, [refreshRemainingQuotes]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sessionId = query.get('session_id');
    if (sessionId) {
      const refreshData = async () => {
        try {
          const subscriptionRes = await axiosInstance.get('/api/subscription');
          setSubscription(subscriptionRes.data);
          setSnackbar({ open: true, message: 'Subscription updated successfully!', severity: 'success' });
          await refreshRemainingQuotes();
        } catch (error) {
          console.error('Error refreshing subscription data:', error);
          setSnackbar({ open: true, message: 'Failed to update subscription.', severity: 'error' });
        }
      };
      refreshData();
    }
  }, [location.search, refreshRemainingQuotes]);

  const handleSubscribe = async (planId: string) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post('/api/subscription/create-checkout-session', { planId });
      const { sessionId, message } = response.data;

      if (sessionId) {
        const stripe = await stripePromise;
        if (stripe) {
          await stripe.redirectToCheckout({ sessionId });
        } else {
          console.error("Stripe hasn't loaded correctly");
          setSnackbar({ open: true, message: "Stripe didn't load correctly.", severity: 'error' });
        }
      } else if (message) {
        setSnackbar({ open: true, message: message, severity: 'success' });
        const subscriptionRes = await axiosInstance.get('/api/subscription');
        setSubscription(subscriptionRes.data);
        await refreshRemainingQuotes();
      } else {
        console.error('Unexpected response:', response.data);
        setSnackbar({ open: true, message: 'Unexpected response from server.', severity: 'error' });
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setSnackbar({ open: true, message: 'Failed to initiate subscription.', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleUnsubscribe = async () => {
    try {
      setProcessingUnsubscribe(true);
      const response = await axiosInstance.post('/api/subscription/unsubscribe');
      if (response.status === 200) {
        setSnackbar({ open: true, message: 'Unsubscription successful!', severity: 'success' });
        setSubscription(null);
        await refreshRemainingQuotes();
      } else {
        setSnackbar({ open: true, message: 'Failed to unsubscribe.', severity: 'error' });
      }
    } catch (error) {
      console.error('Error unsubscribing:', error);
      setSnackbar({ open: true, message: 'Failed to unsubscribe.', severity: 'error' });
    } finally {
      setProcessingUnsubscribe(false);
      setUnsubscribeDialogOpen(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOpenUnsubscribeDialog = () => {
    setUnsubscribeDialogOpen(true);
  };

  const handleCloseUnsubscribeDialog = () => {
    setUnsubscribeDialogOpen(false);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
        <CircularProgress />
      </Box>
    );
  }

// Debug log the subscription (you can remove this once confirmed)
console.log('Subscription response:', subscription);

// Determine the current plan:
//   - If a subscription exists and its plan is "Unlimited", use that.
//   - Else if a subscription exists and its subscriptionObjStatus is "Active", use that.
//   - Otherwise, default to the Free plan.
const currentPlan =
  subscription && subscription.planTier
    ? (subscription.planTier.name === 'Unlimited'
        ? subscription.planTier
        : subscription.subscriptionObjStatus === 'Active'
          ? subscription.planTier
          : plans.find(p => p.name === 'Free'))
    : plans.find(p => p.name === 'Free');

  // Determine if the user is on the Unlimited plan
  const isUnlimitedUser = currentPlan?.name === 'Unlimited';

  // Get quotes generated safely
  const quotesGenerated = subscription?.usage?.quotesGenerated || 0;

  // Calculate quotes remaining and usage percentage
  const quotesRemaining = currentPlan ? currentPlan.quoteLimit - quotesGenerated : 0;
  const percentageUsed = currentPlan && currentPlan.quoteLimit > 0
    ? (quotesGenerated / currentPlan.quoteLimit) * 100
    : 0;

  const getExpirationMessage = () => {
    if (currentPlan?.name === 'Free') {
      return 'No expiration for free plan';
    }

    if (subscription?.compSettingsSubscriptionStatus === 'canceled' && subscription?.subscriptionId) {
      return `${currentPlan?.name} expires on ${new Date(subscription.currentPeriodEnd).toLocaleDateString()}`;
    }

    if (subscription?.currentPeriodEnd) {
      return `Current Period End: ${new Date(subscription.currentPeriodEnd).toLocaleDateString()}`;
    }
  };

  const getFeatures = (planName: string) => {
    switch (planName) {
      case 'Free':
        return ['15 quotes per month', 'Basic support'];
      case 'Pro':
        const proQuotePrice = ((22.50 / 150) * 100).toFixed(0);
        return ['150 quotes per month', `${proQuotePrice} Cents Per Quote`, 'Advanced Support'];
      case 'Cowboy':
        const cowboyQuotePrice = ((54 / 450) * 100).toFixed(0);
        return ['450 quotes per month', `${cowboyQuotePrice} Cents Per Quote`, 'Advanced Support', 'Video Greetings', 'Campaigns'];
      default:
        return [];
    }
    
  };

  const isIOSSubscription = subscription?.subscriptionPlatform === 'ios';
  const isActiveSubscription = subscription?.compSettingsSubscriptionStatus === 'Active';

  return (
    <Box sx={{ flexGrow: 1, p: { xs: 2, md: 4 } }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
        Subscription Manager
      </Typography>

      {currentPlan && (
        <Paper sx={{ p: 3, mb: 4, backgroundColor: 'primary' }}>
          <Typography variant="h6" gutterBottom>
            Current Plan: <strong>{currentPlan.name}</strong>
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress
                variant="determinate"
                value={percentageUsed}
                sx={{ height: 10, borderRadius: 5 }}
                color="warning"
              />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">
                {`${Math.round(percentageUsed)}%`}
              </Typography>
            </Box>
          </Box>
          <Typography variant="body2" sx={{ mb: 1 }}>
            {quotesRemaining} / {currentPlan.quoteLimit} quotes remaining this month
          </Typography>
          <Typography variant="body2" sx={{ mb: 1, mt: 2 }}>
            {getExpirationMessage()}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Subscription Status:{' '}
            <Chip
              label={subscription ? subscription.compSettingsSubscriptionStatus : 'Inactive'}
              color={
                subscription?.compSettingsSubscriptionStatus === 'Active'
                  ? 'success'
                  : subscription?.compSettingsSubscriptionStatus === 'canceled'
                    ? 'warning'
                    : 'default'
              }
            />
          </Typography>

          {subscription?.compSettingsSubscriptionStatus === 'canceled' && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              Your subscription has been cancelled and will expire on{' '}
              {new Date(subscription.currentPeriodEnd).toLocaleDateString()}.
            </Alert>
          )}

          {isActiveSubscription && !isIOSSubscription && (
            <Button
              variant="outlined"
              color="error"
              onClick={handleOpenUnsubscribeDialog}
            >
              Unsubscribe
            </Button>
          )}

          {isActiveSubscription && isIOSSubscription && (
            <Alert severity="info" sx={{ mb: 2 }}>
              This subscription was purchased via an in-app purchase. To manage
              this subscription, please use your mobile device's subscription
              settings.
            </Alert>
          )}
        </Paper>
      )}

<Grid container spacing={4}>
  {plans
  
    // Filter out the "Unlimited" plan
    .filter(plan => plan.name.trim().toLowerCase() !== 'unlimited')
    .map((plan) => (
      <Grid item xs={12} sm={6} md={4} key={plan._id}>
        <StyledPaper 
          elevation={3}
          sx={{
            border: currentPlan?.name === plan.name 
              ? (theme) => `2px solid ${theme.palette.warning.main}` 
              : 'none',
            ...(isUnlimitedUser && { opacity: 0.5, pointerEvents: 'none' }),
          }}
        >
          <Typography variant="h5" component="h2" gutterBottom>
            {plan.name}
          </Typography>
          <Typography variant="h4" component="div" sx={{ my: 2 }}>
            {plan.name === 'Free' ? '$0.00' : `$${plan.name === 'Pro' ? '22.50' : '54'}/month`}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {plan.quoteLimit} quotes per month
          </Typography>
          <Box sx={{ mt: 2, mb: 2 }}>
            {getFeatures(plan.name).map((feature, index) => (
              <Box 
                key={index} 
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}
              >
                <CheckIcon color="warning" sx={{ mr: 1 }} />
                <Typography variant="body2">{feature}</Typography>
              </Box>
            ))}
          </Box>
          {plan.name !== 'Free' && (
            <Button
              variant="contained"
              color="warning"
              onClick={() => handleSubscribe(plan._id)}
              disabled={currentPlan?.name === plan.name || isIOSSubscription}
              fullWidth
            >
              {currentPlan?.name === plan.name 
                ? 'Current Plan' 
                : isIOSSubscription 
                  ? 'Change Plan in App' 
                  : 'Select Plan'}
            </Button>
          )}
        </StyledPaper>
      </Grid>
    ))
  }
</Grid>

      <Box sx={{ mt: 6 }}>
        <Typography variant="h5" gutterBottom>
          Compare Plans
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: 'primary.main' }}>
                <TableCell sx={{ color: 'primary.contrastText' }}>Plan</TableCell>
                <TableCell align="center" sx={{ color: 'primary.contrastText' }}>Quotes / Month</TableCell>
                <TableCell align="center" sx={{ color: 'primary.contrastText' }}>Price</TableCell>
                <TableCell align="center" sx={{ color: 'primary.contrastText' }}>Video Greetings</TableCell>
                <TableCell align="center" sx={{ color: 'primary.contrastText' }}>Campaigns</TableCell>
                <TableCell align="right" sx={{ color: 'primary.contrastText' }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {plans
                .filter(plan => plan.name !== 'Unlimited')
                .map((plan) => (
                  <TableRow key={plan._id} hover sx={isUnlimitedUser ? { opacity: 0.5 } : {}}>
                    <TableCell component="th" scope="row">
                      {plan.name}
                    </TableCell>
                    <TableCell align="center">{plan.quoteLimit}</TableCell>
                    <TableCell align="center">{plan.name === 'Free' ? 'Free' : `$${plan.name === 'Pro' ? '22.50' : '54.00'}`}</TableCell>
                    <TableCell align="center">{plan.name === 'Free' ? '-' : `${plan.name === 'Pro' ? '-' : 'Yes!'}`}</TableCell>
                    <TableCell align="center">{plan.name === 'Free' ? '-' : `${plan.name === 'Pro' ? '-' : 'Yes!'}`}</TableCell>
                    <TableCell align="right">
                      <Chip
                        label={currentPlan?.name === plan.name ? 'Current' : 'Available'}
                        color={currentPlan?.name === plan.name ? 'warning' : 'default'}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Unsubscribe Confirmation Dialog */}
      <Dialog
        open={unsubscribeDialogOpen}
        onClose={handleCloseUnsubscribeDialog}
      >
        <DialogTitle>Confirm Unsubscription</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to unsubscribe from your current plan? You will lose access to premium features.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUnsubscribeDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUnsubscribe} color="error" disabled={processingUnsubscribe}>
            {processingUnsubscribe ? <CircularProgress size={24} /> : 'Unsubscribe'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SubscriptionManager;