import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Card,
  CardContent,
} from '@mui/material';
import {
  Assessment,
  AttachMoney,
  EmojiEvents,
  TrendingUp,
  Visibility,
  ShowChart,
  CheckCircle
} from '@mui/icons-material';
import { useTheme, alpha, styled } from '@mui/material/styles';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import axiosInstance from '../utils/axiosConfig';
import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface Achievement {
  type: 'MOST_ACTIVE' | 'SHARPSHOOTER' | 'EAGLE_EYE';
  earnedAt: string | Date;
  description: string;
  displayName: string;
  value: number;
  month: number;
  year: number;
}

interface MonthlyStat {
  month: number;
  year: number;
  quoteCount: number;
  acceptedCount: number;
  viewedQuoteCount: number;
  totalValue: number;
}

interface Performance {
  quoteCount: number;
  acceptedQuoteCount: number;
  totalQuoteValue: number;
  viewedQuoteCount: number;
  // averageResponseTime?: number; // <-- Removed reference
  monthlyStats: MonthlyStat[];
}

interface CalculatedMetrics {
  highestValue: number;
  averageSaleValue: number;
  currentMonthName: string;
  currentMonthYear: number;
}

interface UserData {
  userId: string;
  name: string;
  email: string;
  role: string;
  createdAt: string;
  userColor: string;
  performance: Performance;
  achievements: Achievement[];
}

interface StatCardProps {
  title: string;
  value: string | number;
  icon: React.ReactNode;
  trend: number | null;
  color: string;
}

const formatAchievementDate = (date: string | Date) => {
  const achievementDate = new Date(date);
  return achievementDate.toLocaleDateString('en-US', {
    month: 'short',
    year: 'numeric'
  });
};

// Achievement badge definitions with their corresponding image paths
const achievementBadges = {
  MOST_ACTIVE: '/assets/badges/most-active-badge.webp',
  SHARPSHOOTER: '/assets/badges/sharpshooter-badge.webp',
  EAGLE_EYE: '/assets/badges/quick-draw-badge.webp'
};

const calculateTrend = (currentValue: number | null, stats: MonthlyStat[]): number | null => {
  if (!stats || stats.length < 2 || currentValue === null) return null;

  // Sort stats by year and month in descending order
  const sortedStats = [...stats].sort((a, b) => {
    if (a.year === b.year) return b.month - a.month;
    return b.year - a.year;
  });

  // Get current and previous month stats
  const currentMonth = sortedStats[0];
  const lastMonth = sortedStats[1];

  if (!currentMonth || !lastMonth) return null;

  // For a basic trend, let's just base it on quoteCount or the numeric fields
  const getCurrentValue = (stat: MonthlyStat): number => {
    if (stat.quoteCount === 0) return 0;
    return stat.quoteCount;
  };

  const lastMonthValue = getCurrentValue(lastMonth);
  if (lastMonthValue === 0) return null;

  return ((currentValue - lastMonthValue) / lastMonthValue) * 100;
};

const calculateAdditionalMetrics = (performance: Performance): CalculatedMetrics => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  // Calculate highest value and average sale value
  const highestValue = performance.monthlyStats.reduce(
    (max, stat) => Math.max(max, stat.totalValue),
    0
  );

  const averageSaleValue =
    performance.acceptedQuoteCount > 0
      ? performance.totalQuoteValue / performance.acceptedQuoteCount
      : 0;

  return {
    highestValue,
    averageSaleValue,
    currentMonthName: new Date(2024, currentMonth).toLocaleString('default', {
      month: 'long'
    }),
    currentMonthYear: currentYear
  };
};

const StatCardWrapper = styled(Card)(({ theme }) => ({
  height: '100%',
  position: 'relative',
  overflow: 'visible',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4],
  },
}));

const StatCard: React.FC<StatCardProps> = ({ title, value, icon, trend, color }) => {
  return (
    <StatCardWrapper>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Box
            sx={{
              backgroundColor: alpha(color, 0.1),
              borderRadius: 1,
              p: 1,
              mr: 2,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {icon}
          </Box>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {title}
          </Typography>
        </Box>
        <Typography variant="h4" gutterBottom>
          {value}
        </Typography>
        {trend !== null && (
          <Box display="flex" alignItems="center" mt={1}>
            <TrendingUp
              sx={{
                color: trend >= 0 ? 'success.main' : 'error.main',
                mr: 0.5,
                fontSize: '1rem'
              }}
            />
            <Typography
              variant="body2"
              color={trend >= 0 ? 'success.main' : 'error.main'}
            >
              {trend.toFixed(1)}% from last month
            </Typography>
          </Box>
        )}
      </CardContent>
    </StatCardWrapper>
  );
};

const ViewUser: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (!userId) {
        setError('User ID is required');
        setLoading(false);
        return;
      }
      try {
        const response = await axiosInstance.get<UserData>(`/api/users/${userId}`);
        console.log('Fetched user data:', response.data);

        if (!response.data) {
          throw new Error('No data received from server');
        }
        setUserData(response.data);
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Failed to load user data';
        console.error('Error fetching user data:', err);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!userData) return null;

  const { performance } = userData;

  // Calculate metrics
  const additionalMetrics = calculateAdditionalMetrics(performance);

  // Trends
  const quoteCountTrend = calculateTrend(performance?.quoteCount || 0, performance?.monthlyStats || []);
  const acceptanceRateTrend = performance?.quoteCount
    ? calculateTrend(
        (performance.acceptedQuoteCount / performance.quoteCount) * 100,
        performance?.monthlyStats || []
      )
    : null;
  const valueTrend = calculateTrend(performance?.totalQuoteValue || 0, performance?.monthlyStats || []);

  // Calculate "Viewed Percentage" = viewedQuoteCount / quoteCount * 100
  const viewedPercentage = performance.quoteCount
    ? (performance.viewedQuoteCount / performance.quoteCount) * 100
    : 0;

  // For "Success Rate"
  const successRate =
    performance.quoteCount > 0
      ? ((performance.acceptedQuoteCount / performance.quoteCount) * 100).toFixed(1)
      : '0.0';

  return (
    <Box sx={{ p: { xs: 2, sm: 3 }, pb: 6 }}>
      {/* Header Section */}
      <Paper
        elevation={3}
        sx={{
          p: { xs: 2, sm: 3 },
          mb: 4,
          background: `linear-gradient(60deg, ${alpha(
            userData.userColor,
            0.3
          )} 0%, ${alpha(theme.palette.background.paper, 0.5)} 100%)`,
          borderRadius: 2,
        }}
      >
        <Box display="flex" alignItems="center" gap={3}>
          <IconButton
            onClick={handleBack}
            sx={{
              mr: 2,
              width: 40,
              height: 40,
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.2)
              }
            }}
          >
            <ArrowBack />
          </IconButton>
          <Box>
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
              {userData.name}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {userData.role} · Active since{' '}
              {new Date(userData.createdAt).toLocaleDateString()}
            </Typography>
          </Box>
        </Box>
      </Paper>

      {/* Month Heading */}
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          mb: 4,
          textAlign: 'center',
          background: `linear-gradient(45deg, ${theme.palette.info.light}, ${theme.palette.primary.light})`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          fontWeight: 'bold'
        }}
      >
        {`${additionalMetrics.currentMonthName} ${additionalMetrics.currentMonthYear} Performance`}
      </Typography>

      {/* Stats Grid */}
      <Grid container spacing={3} mb={4}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Activated Quotes"
            value={performance.quoteCount}
            icon={<Assessment sx={{ color: theme.palette.info.main }} />}
            color={theme.palette.info.light}
            trend={quoteCountTrend}
          />
        </Grid>
        {/* Viewed Quotes */}
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Viewed Quotes"
            value={performance.viewedQuoteCount}
            icon={<Visibility sx={{ color: theme.palette.secondary.main }} />}
            color={theme.palette.secondary.main}
            trend={calculateTrend(
              performance.viewedQuoteCount || 0,
              performance.monthlyStats || []
            )}
          />
        </Grid>
 {/* Viewed Percentage (replaces Avg Response to View) */}
 <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Viewed %"
            value={`${viewedPercentage.toFixed(1)}%`}
            icon={<Visibility sx={{ color: theme.palette.info.main }} />}
            color={theme.palette.info.main}
            trend={null} // or calculate a trend if you want
          />
        </Grid>

        {/* Accepted Quotes */}
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Accepted Quotes"
            value={performance.acceptedQuoteCount}
            icon={<CheckCircle sx={{ color: theme.palette.success.main }} />}
            color={theme.palette.success.light}
            trend={calculateTrend(
              performance.acceptedQuoteCount || 0,
              performance.monthlyStats || []
            )}
          />
        </Grid>
        {/* Success Rate */}
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Success Rate"
            value={`${successRate}%`}
            icon={<EmojiEvents sx={{ color: theme.palette.warning.main }} />}
            color={theme.palette.warning.main}
            trend={acceptanceRateTrend}
          />
        </Grid>

        {/* Highest Sale */}
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Highest Sale"
            value={`$${additionalMetrics.highestValue.toLocaleString()}`}
            icon={<TrendingUp sx={{ color: theme.palette.success.dark }} />}
            color={theme.palette.success.dark}
            trend={null}
          />
        </Grid>

        {/* Average Sale Value */}
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Avg Sale Value"
            value={`$${additionalMetrics.averageSaleValue.toLocaleString()}`}
            icon={<ShowChart sx={{ color: theme.palette.info.dark }} />}
            color={theme.palette.info.dark}
            trend={null}
          />
        </Grid>

        {/* Total Accepted */}
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Accepted $"
            value={`$${performance.totalQuoteValue.toLocaleString()}`}
            icon={<AttachMoney sx={{ color: theme.palette.success.main }} />}
            color={theme.palette.success.main}
            trend={valueTrend}
          />
        </Grid>

       
      </Grid>

      {/* Performance Chart */}
      <Paper elevation={3} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
          Monthly Performance
        </Typography>
        <Box sx={{ width: '100%', height: 400 }}>
          {performance.monthlyStats && performance.monthlyStats.length > 0 ? (
            <ResponsiveContainer>
              <LineChart data={performance.monthlyStats}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="month"
                  tickFormatter={(value: number) => {
                    const date = new Date(2024, value - 1);
                    return date.toLocaleString('default', { month: 'short' });
                  }}
                />
                <YAxis yAxisId="left" />
                <YAxis yAxisId="right" orientation="right" />
                <Tooltip />
                <Legend />
                <Line
                  yAxisId="left"
                  type="monotone"
                  dataKey="quoteCount"
                  name="Quotes"
                  stroke={theme.palette.warning.main}
                />
                <Line
                  yAxisId="left"
                  type="monotone"
                  dataKey="viewedQuoteCount"
                  name="Viewed"
                  stroke={theme.palette.secondary.main}
                />
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="totalValue"
                  name="Value ($)"
                  stroke={theme.palette.success.main}
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Typography color="text.secondary">
                No performance data available
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>

      {/* Achievements Section (Unmodified) */}
      <Paper elevation={3} sx={{ p: 3, borderRadius: 2, mt: 4 }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
          Achievements
        </Typography>
        <Grid container spacing={3}>
          {userData.achievements?.map((achievement, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={{
                  position: 'relative',
                  width: '200px',
                  height: '200px',
                  margin: 'auto'
                }}
              >
                {/* Base Achievement Badge */}
                <Box
                  component="img"
                  src={achievementBadges[achievement.type as keyof typeof achievementBadges]}
                  alt={achievement.type}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain'
                  }}
                />

                {/* Date Overlay */}
                <Typography
                  sx={{
                    position: 'absolute',
                    top: '45%', // Adjust as needed
                    left: '50%',
                    transform: 'translate(-50%, 140%)',
                    color: 'primary.main',
                    fontWeight: 'bold',
                    fontSize: '.75rem',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
                    backgroundColor: 'rgba(255,255,255,0)',
                    padding: '2px 8px',
                    borderRadius: '4px'
                  }}
                >
                  {formatAchievementDate(achievement.earnedAt)}
                </Typography>

                {/* Achievement Info */}
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'rgba(41, 41, 41, 0.9)',
                    padding: 1,
                    borderRadius: '0 0 8px 8px',
                    textAlign: 'center',
                    opacity: 0,
                    transition: 'opacity 0.2s ease-in-out',
                    '.MuiBox-root:hover > &': {
                      opacity: 1
                    }
                  }}
                >
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    {achievement.type
                      .split('_')
                      .map(
                        (word) => word.charAt(0) + word.slice(1).toLowerCase()
                      )
                      .join(' ')}
                  </Typography>
                  <Typography variant="caption" display="block">
                    {achievement.description}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
          {(!userData.achievements || userData.achievements.length === 0) && (
            <Grid item xs={12}>
              <Box
                sx={{
                  textAlign: 'center',
                  py: 4,
                  backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.05),
                  borderRadius: 2
                }}
              >
                <Typography color="text.secondary">
                  No achievements earned yet
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Box>
  );
};

export default ViewUser;