import React, { useState, useEffect } from 'react';
import {
  Badge,
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Chip,
  Divider,
} from '@mui/material';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import axiosInstance from '../utils/axiosConfig';
import { useSnackbar } from 'notistack';

interface Notification {
  _id: string;
  title: string;
  message: string;
  type: 'ANNOUNCEMENT' | 'MAINTENANCE' | 'FEATURE' | 'ALERT';
  createdAt: string;
  expiresAt: string | null;
  isRead: boolean;
}

const typeColors = {
  ANNOUNCEMENT: 'primary',
  MAINTENANCE: 'warning',
  FEATURE: 'success',
  ALERT: 'error',
} as const;

export default function UserNotifications() {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [popoverOpened, setPopoverOpened] = useState(false);

  useEffect(() => {
    const userDataString = localStorage.getItem('userData');
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      fetchNotifications(userData.companyName);
    }
  }, []);

  const fetchNotifications = async (companyName: string) => {
    try {
      const response = await axiosInstance.get(`/api/notifications/company/${companyName}`);
      setNotifications(response.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setPopoverOpened(true);

    // Mark all unread notifications as read when opening the popover
    const unreadIds = notifications
      .filter(notification => !notification.isRead)
      .map(notification => notification._id);

    if (unreadIds.length > 0) {
      try {
        await axiosInstance.post('/api/notifications/mark-read', {
          notificationIds: unreadIds
        });

        // Update local state to mark notifications as read
        setNotifications(prevNotifications =>
          prevNotifications.map(notification => ({
            ...notification,
            isRead: true
          }))
        );
      } catch (error) {
        console.error('Error marking notifications as read:', error);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const formatDate = (date: string) => {
    return new Date(date).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const unreadCount = notifications.filter(notification => !notification.isRead).length;
  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton 
        color="inherit" 
        onClick={handleClick}  
        sx={{
          position: 'fixed',
          right: 170,
          top: 20,
          width: 60,
          height: 60,
          zIndex:999,
          color: 'info',
          '&:hover': {
            bgcolor: 'warning.main',
          },
        }}
      >
        <Badge 
          badgeContent={unreadCount} 
          color="error"
          sx={{
            '& .MuiBadge-badge': {
              bgcolor: unreadCount > 0 ? 'error.main' : 'transparent',
            }
          }}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            width: 400,
            maxHeight: 500,
          },
        }}
      >
        <Box sx={{ p: 2, bgcolor: 'background.paper' }}>
          <Typography variant="h6" gutterBottom>
            Notifications
          </Typography>
          {notifications.length === 0 ? (
            <Typography color="textSecondary" align="center" sx={{ py: 4 }}>
              No new notifications
            </Typography>
          ) : (
            <List sx={{ width: '100%', p: 0 }}>
              {notifications.map((notification, index) => (
                <React.Fragment key={notification._id}>
                  {index > 0 && <Divider />}
                  <ListItem 
                    alignItems="flex-start" 
                    sx={{ 
                      px: 0,
                      bgcolor: notification.isRead ? 'transparent' : 'action.hover',
                      transition: 'background-color 0.3s'
                    }}
                  >
                    <Box sx={{ width: '100%' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Chip
                          label={notification.type}
                          color={typeColors[notification.type]}
                          size="small"
                        />
                        <Typography variant="caption" color="textSecondary">
                          {formatDate(notification.createdAt)}
                        </Typography>
                      </Box>
                      <Typography variant="subtitle2" gutterBottom>
                        {notification.title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {notification.message}
                      </Typography>
                      {notification.expiresAt && (
                        <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mt: 1 }}>
                          Expires: {formatDate(notification.expiresAt)}
                        </Typography>
                      )}
                    </Box>
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          )}
        </Box>
      </Popover>
    </>
  );
}