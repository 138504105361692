import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { 
  Box, Typography, Grid, Card, CardContent, IconButton, CircularProgress, Tooltip, 
  Button, Collapse
} from '@mui/material';
import { 
  Home, Description, FileCopy, Build, Bungalow, Settings as SettingsIcon, 
  Logout as LogoutIcon, Brightness4, Brightness7, ShoppingBag as ShoppingBagIcon, 
  AutoStories as AutoStoriesIcon, Apple, ExpandMore, Campaign 
} from '@mui/icons-material';
import axiosInstance from '../utils/axiosConfig';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import Quotes from './Quotes';
import Customers from './Customers';
import Logout from './Logout';
import Templates from './Templates';
import Settings from './Settings';
import ViewUser from './ViewUser';
import TrailGuide from './TrailGuide/TrailGuide';
import ViewQuote from './ViewQuote';
import CreateNew from './CreateNew';
import WelcomeModal from './WelcomeModal'; 
import TermsOfServiceModal from './TermsOfServiceModal';
import PredefinedServicesPage from './PredefinedServicesPage';
import SubscriptionManager from './SubscriptionManager';
import ViewAllQuotes from './ViewAllQuotes';
import { ColorModeContext } from '../App';
import { useTheme } from '@mui/material/styles';
import QuoteMap from './QuoteMap';
import { useAuth } from '../AuthContext';
import UserNotifications from './UserNotifications';
import GetSupplies from './GetSupplies';
import UserChip from './UserChip';
import { FilterList, Person, Group } from '@mui/icons-material';
import dayjs from 'dayjs';
import TheRoundup from './TheRoundup';


// NEW IMPORT
import CustomFilter from './CustomFilter';
import CampaignsList from './campaigns/CampaignsList';
import CampaignDetails from './campaigns/CampaignDetails';

interface Quote {
  quoteId: string;
  templateId: string;
  templateName: string;
  customerName: string;
  services: string[];
  serviceValues: string[];
  location?: {
    latitude: number;
    longitude: number;
  };
  activatedTime: string;
  acceptedTime: string;
  totalValue: number;
  status: 'Active' | 'Accepted';
  activatedByUserId: string;
  activatedByUserName: string;
}

interface QuoteTemplate {
  templateId: string;
  name: string;
  services: string[];
  companyName: string;
  createdBy: string;
  createdAt: string;
}

interface User {
  userId: string;
  name: string;
  email: string;
  companyName: string;
  role: string;
}

interface Customer {
  quoteId: string;
  addressLocation?: {
    latitude: number;
    longitude: number;
  };
  firstName: string;
  lastName: string;
}

interface CompanySettings {
  companyName: string;
  companyPhoneNumber: string;
  companyLogo: string | null;
  planTier: string;
  whoWeAreText: string;
  googleMapEmbed: string;
  websiteLink: string;
  compLocation?: {
    latitude: number;
    longitude: number;
  };
}

const TOS_VERSION = '1.0.0';

// Keep the same date filter options
const dateFilterOptions = [
  { label: 'Last 30 days', value: 30 },
  { label: 'Last 60 days', value: 60 },
  { label: 'Last 90 days', value: 90 },
  { label: 'Last 180 days', value: 180 },
  { label: 'Last Year', value: 365 },
  { label: 'All Time', value: null },
];

const Dashboard: React.FC = () => {
  // State
  const [activeQuotes, setActiveQuotes] = useState<Quote[]>([]);
  const [acceptedQuotes, setAcceptedQuotes] = useState<Quote[]>([]);
  const [preparedActiveQuotes, setPreparedActiveQuotes] = useState<Quote[]>([]);
  const [preparedAcceptedQuotes, setPreparedAcceptedQuotes] = useState<Quote[]>([]);
  const [quoteTemplates, setQuoteTemplates] = useState<QuoteTemplate[]>([]);
  const [user, setUser] = useState<User | null>(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showTosModal, setShowTosModal] = useState(false);
  const [tosAccepted, setTosAccepted] = useState(false);
  const [companySettings, setCompanySettings] = useState<CompanySettings | null>(null);
  const [moreOpen, setMoreOpen] = useState(false);

  // We'll keep user colors separate for top stat cards vs. map icons
  const [userColors, setUserColors] = useState<{ [key: string]: string }>({});
  const [mapUserColors, setMapUserColors] = useState<{ [key: string]: string }>({});

  // Toggle: 'my-quotes' | 'all-cowboys'
  const [quoteViewMode, setQuoteViewMode] = useState<'my-quotes' | 'all-cowboys'>('my-quotes');

  // Date filter
  const [dateFilter, setDateFilter] = useState<number | null>(30);

  const colorMode = useContext(ColorModeContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  const hasFetched = useRef(false);

  // ============== Callbacks/Functions ==============
  const fetchCustomerData = useCallback(async (quoteId: string, companyName: string): Promise<Customer | null> => {
    try {
      const response = await axiosInstance.get(`/api/customers/customerByQuote/${quoteId}`, {
        params: { companyName }
      });
      return response.data;
    } catch (error: any) {
      console.error(`Error fetching customer data for quote ${quoteId}:`, error.response?.data || error.message);
      return null;
    }
  }, []);

  const handleTosAcceptComplete = useCallback(async () => {
    setTosAccepted(true);
    setShowTosModal(false);

    try {
      const settings = await fetchCompanySettings();
      if (!settings?.compLocation) {
        setShowWelcomeModal(true);
      }
    } catch (error: any) {
      console.error('Error fetching company settings after ToS acceptance:', error);
      setError('Failed to load company settings after accepting Terms of Service.');
    }
  }, []);

  const fetchCompanySettings = async (): Promise<CompanySettings> => {
    try {
      const response = await axiosInstance.get('/api/compSettings');
      setCompanySettings(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching company settings:', error);
      throw error;
    }
  };

  const fetchUserColorsForQuotes = async (quotes: Quote[]) => {
    try {
      const uniqueUserIds = Array.from(new Set(quotes.map(q => q.activatedByUserId)));
      if (uniqueUserIds.length === 0) return {};

      const response = await axiosInstance.post('/api/users/colors', { userIds: uniqueUserIds });
      const colorMap = response.data.reduce(
        (acc: { [key: string]: string }, u: { userId: string; userColor: string }) => {
          acc[u.userId] = u.userColor;
          return acc;
        }, 
        {}
      );
      return colorMap;
    } catch (error) {
      console.error('Error fetching user colors:', error);
      return {};
    }
  };

  /**
   * The main function to fetch quotes for "Active" and "Accepted" from /all route,
   * respecting the current quoteViewMode and dateFilter.
   */
  const fetchQuoteData = async (userData: User) => {
    try {
      setLoading(true);
      setError(null);

      const startDate = dateFilter
        ? dayjs().subtract(dateFilter, 'day').format('YYYY-MM-DD')
        : null;

      const paramsActive: any = {
        viewMode: quoteViewMode,
        status: 'Active',
      };
      const paramsAccepted: any = {
        viewMode: quoteViewMode,
        status: 'Accepted',
      };
      if (startDate) {
        paramsActive.startDate = startDate;
        paramsAccepted.startDate = startDate;
      }

      const [templatesResponse, activeQuotesResponse, acceptedQuotesResponse] = await Promise.all([
        axiosInstance.get('/api/quoteTemplates'),
        axiosInstance.get(`/api/quotes/all/${userData.userId}`, { params: paramsActive }),
        axiosInstance.get(`/api/quotes/all/${userData.userId}`, { params: paramsAccepted }),
      ]);

      setQuoteTemplates(templatesResponse.data);
      setActiveQuotes(activeQuotesResponse.data);
      setAcceptedQuotes(acceptedQuotesResponse.data);

      // Prepare Active
      const preparedActive = activeQuotesResponse.data.map((q: Quote) => ({
        ...q,
        popupContent: `
          <strong>${q.templateName}</strong><br/>
          Activated: ${new Date(q.activatedTime).toLocaleString()}<br/>
          Total Value: $${q.totalValue.toFixed(2)}
        `
      }));

      // Prepare Accepted (fetch location from customers if needed)
      const preparedAcceptedPromises = acceptedQuotesResponse.data.map(async (q: Quote) => {
        const customer = await fetchCustomerData(q.quoteId, userData.companyName);
        return {
          ...q,
          location: customer?.addressLocation,
          popupContent: `
            <strong>${q.customerName}</strong><br/>
            Accepted: ${new Date(q.acceptedTime).toLocaleString()}<br/>
            Total Value: $${q.totalValue.toFixed(2)}
          `
        };
      });
      const acceptedWithLocations = await Promise.all(preparedAcceptedPromises);
      const finalAccepted = acceptedWithLocations.filter(q => q.location);

      // Handle user colors
      if (quoteViewMode === 'all-cowboys') {
        const allColors = await fetchUserColorsForQuotes([...preparedActive, ...finalAccepted]);
        // Keep top-level userColors for the "Recently Accepted" cards
        setUserColors(prev => ({ ...prev, ...allColors }));
        // Also set the map userColors
        setMapUserColors(allColors);
      } else {
        // "My Quotes" => do not blow away the top-level userColors, but clear the map colors
        setMapUserColors({});
      }

      setPreparedActiveQuotes(preparedActive);
      setPreparedAcceptedQuotes(finalAccepted);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching quote data:', error);
      setError('Failed to load quote data. Please try again.');
      setLoading(false);
    }
  };

  // This is the main "fetch" triggered once we have user data and TOS is accepted
  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      setError(null);

      const userDataString = localStorage.getItem('userData');
      if (!userDataString) {
        throw new Error('User data not found');
      }
      const userData: User = JSON.parse(userDataString);
      setUser(userData);

      // Check TOS acceptance
      const tosResponse = await axiosInstance.get('/api/tos/status');
      if (!tosResponse.data.tosAccepted || tosResponse.data.tosAcceptedVersion !== TOS_VERSION) {
        setShowTosModal(true);
      } else {
        setTosAccepted(true);
        const settings = await fetchCompanySettings();
        if (!settings?.compLocation) {
          setShowWelcomeModal(true);
        }
        // We'll fetch quotes in useEffect depending on quoteViewMode
      }

      setLoading(false);
    } catch (error: any) {
      console.error('Error fetching dashboard data:', error);
      setError('Failed to load dashboard data. Please try again.');
      setLoading(false);
    }
  };

  const handleLocationSet = async (latitude: number, longitude: number) => {
    try {
      await axiosInstance.post('/api/compSettings/setLocation', {
        latitude,
        longitude,
      });

      const updatedSettings: CompanySettings = {
        ...companySettings!,
        compLocation: { latitude, longitude }
      };
      setCompanySettings(updatedSettings);
      setShowWelcomeModal(false);

      // re-fetch company settings
      const newSettings = await fetchCompanySettings();
      // if user is set, fetch quotes again
      const userDataString = localStorage.getItem('userData');
      if (userDataString) {
        const userData = JSON.parse(userDataString);
        await fetchQuoteData(userData);
      }
    } catch (error) {
      console.error('Error setting location:', error);
      setError('Failed to set location. Please try again.');
    }
  };

  
  const toggleSidebar = () => {
    setSidebarExpanded(!sidebarExpanded);
  };

  const handleTemplateClick = (template: QuoteTemplate) => {
    navigate('/dashboard/templates', { state: { selectedTemplate: template } });
  };

  // ============== useEffects ==============
  useEffect(() => {
    // If not authenticated, push to login
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    if (hasFetched.current) return;
    hasFetched.current = true;

    fetchDashboardData(); 
  }, [isAuthenticated, navigate]);

  // Whenever quoteViewMode or dateFilter changes, re-fetch quotes if we already have user data & TOS is done
  useEffect(() => {
    if (!user || !tosAccepted) return; 
    fetchQuoteData(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteViewMode, dateFilter, user, tosAccepted]);

  // ============== Subcomponents ==============
  const DashboardHome = () => (
    <>
      <Typography variant="h5" align='center' gutterBottom sx={{ fontWeight:'bold', mt: 4 }}>
        Howdy {user?.name ? user.name.split(' ')[0] : 'User'}
      </Typography>

      {/* Recently Accepted Quotes */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Recently Accepted Quotes
        </Typography>
        <Box sx={{ display: 'flex', overflowX: 'auto', gap: 2, mt: 2, pb: 1 }}>
          {preparedAcceptedQuotes.map((quote) => (
            <Card
              key={quote.quoteId}
              onClick={() => navigate(`/dashboard/internal/quote/${quote.quoteId}`)}
              sx={{
                cursor: 'pointer',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: 3,
                },
                minWidth: 250,
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: 200
              }}
            >
              <CardContent 
                sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: '100%',
                  p: 2
                }}
              >
                <Box sx={{ textAlign: 'center' }}>
                  <Bungalow sx={{ fontSize: 40, color: 'warning.main', mb: 1, mt: 1 }} />
                  <Typography variant="subtitle2" noWrap>
                    {quote.customerName}
                  </Typography>
                  <Typography variant="body2" color="inherit" fontWeight="bold">
                    ${quote.totalValue.toFixed(2)}
                  </Typography>
                  <Typography variant="caption" noWrap>
                    {new Date(quote.acceptedTime).toLocaleDateString()}
                  </Typography>
                </Box>

                <Box>
                  {/* Using userColors here so it's unaffected by toggle */}
                  <UserChip
                    userId={quote.activatedByUserId}
                    userName={quote.activatedByUserName}
                    userColor={userColors[quote.activatedByUserId] || '#666'}
                    size="small"
                  />
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>

      {/* Maps Section */}
      <Box sx={{ mb: 4, position: 'relative' }}>
        <Box sx={{
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 2,
          position: 'relative',
          zIndex: 1000
        }}>
          <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
            Maps
          </Typography>

          <Box sx={{ display: 'flex', gap: 2, position: 'relative' }}>
           

            {/* NEW: Use our CustomFilter component */}
            <CustomFilter
              dateFilterOptions={dateFilterOptions}
              currentValue={dateFilter}
              onChange={(val) => setDateFilter(val)}
            />

             {/* Toggle between 'my-quotes' / 'all-cowboys' */}
             <Button
              variant="contained"
              color="secondary"
              sx={{width:200}}
              onClick={() => {
                setQuoteViewMode(prev => prev === 'my-quotes' ? 'all-cowboys' : 'my-quotes');
              }}
              startIcon={quoteViewMode === 'my-quotes' ? <Person /> : <Group />}
            >
              {quoteViewMode === 'my-quotes' ? 'My Quotes' : 'All Cowboys'}
            </Button>
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography sx={{mb:1.5}}>Active Quotes</Typography>
              
                <QuoteMap 
                  quotes={preparedActiveQuotes} 
                  companyLocation={companySettings?.compLocation}
                  userColors={mapUserColors}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography sx={{mb:1.5}}>Accepted Quotes</Typography>
              
                <QuoteMap 
                  quotes={preparedAcceptedQuotes} 
                  companyLocation={companySettings?.compLocation}
                  userColors={mapUserColors}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Quote Templates */}
      <Box>
        <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
          Quote Templates
        </Typography>
        <Box sx={{ display: 'flex', overflowX: 'auto', gap: 2, mt: 2, pb: 1 }}>
          {quoteTemplates.map((template) => (
            <Card
              key={template.templateId}
              onClick={() => handleTemplateClick(template)}
              sx={{
                cursor: 'pointer',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: 3,
                },
                minWidth: 250,
                flex: '0 0 auto',
              }}
            >
              <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <FileCopy sx={{ fontSize: 40, color: 'warning.main', mb: 1, mt: 1 }} />
                <Typography variant="subtitle2" noWrap>{template.name}</Typography>
                <Typography variant="caption" noWrap>
                  {new Date(template.createdAt).toLocaleDateString()}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>
    </>
  );

  // ============== Early Returns ==============
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography color="error" variant="h6" gutterBottom>
          {error}
        </Typography>
        <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
          Retry
        </Button>
      </Box>
    );
  }

  // ============== Main Layout ==============
  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      {/* Sidebar */}
      <Box
        sx={{
          width: sidebarExpanded ? 220 : 60,
          flexShrink: 0,
          color: 'white',
          p: 2,
          transition: 'width 0.3s, box-shadow 0.3s',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'primary.main',
          boxShadow: sidebarExpanded
            ? '2px 0 10px rgba(0, 0, 0, 0.4)'
            : '2px 0 5px rgba(0, 0, 0, 0.4)',
          zIndex: 1000,
        }}
      >
        {/* Logo + toggle */}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              borderRadius: '50%',
              p: 0.5,
              transition: 'transform 0.3s',
              '&:hover': {
                transform: sidebarExpanded ? 'scale(1)' : 'scale(1.1)',
              },
            }}
            onClick={toggleSidebar}
          >
            <img
              src="/logo192.png"
              alt="CowboyQuote Logo"
              style={{
                width: 30,
                height: 30,
                marginRight: sidebarExpanded ? 5 : 0,
                marginLeft: -5,
                marginTop: 5,
                transition: 'transform 0.3s',
                transform: sidebarExpanded ? 'scale(1)' : 'scale(1.1)',
              }}
            />
            {sidebarExpanded && (
              <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 1 }}>
                CowboyQuote
              </Typography>
            )}
          </Box>
        </Box>

        {/* Nav Links */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, flexGrow: 1 }}>
          {[
            { path: '/dashboard', icon: <Home />, label: 'Home' },
            { path: '/dashboard/quotes', icon: <Description />, label: 'Quotes' },
            { path: '/dashboard/predefined-services', icon: <Build />, label: 'Services', hideFor: ['User'] },
            { path: '/dashboard/templates', icon: <FileCopy />, label: 'Templates' },
            { path: '/dashboard/customers', icon: <Bungalow />, label: 'Customers' },
            { path: '/dashboard/theroundup', icon: <Person />, label: 'The Roundup' },
            { 
              path: '/dashboard/campaigns', 
              icon: <Campaign />, 
              label: 'Campaigns',
              showIf: companySettings?.planTier === '67021f7493849a3775c17c14' ||  companySettings?.planTier === '67a4c05935efd64848503bc2'  // COWBOY_PLAN_ID 
            },
            { path: '/dashboard/settings', icon: <SettingsIcon />, label: 'Settings' },
          ]
            .filter(item => {
              if (item.hideFor && user?.role && item.hideFor.includes(user.role)) {
                return false; 
              }
              return true;
            })
            .map(item => (
              <Link
                key={item.path}
                to={item.path}
                style={{ textDecoration: 'none', color: 'white' }}
              >
                <Tooltip title={!sidebarExpanded ? item.label : ''} placement="right" arrow>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: sidebarExpanded ? 'flex-start' : 'center',
                      gap: 2,
                      px: sidebarExpanded ? 1 : 0,
                      minHeight: 40,
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        '& .MuiSvgIcon-root': {
                          color: 'warning.main',
                          transform: 'scale(1.1)',
                        },
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        '& .MuiSvgIcon-root': {
                          transition: 'all 0.2s ease',
                          fontSize: 24,
                        },
                      }}
                    >
                      {item.icon}
                    </Box>
                    {sidebarExpanded && (
                      <Typography
                        sx={{
                          transition: 'opacity 0.2s ease',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {item.label}
                      </Typography>
                    )}
                  </Box>
                </Tooltip>
              </Link>
            ))
          }
        </Box>

        {/* "More" Section */}
        <Box sx={{ mt: 'auto', width: '100%' }}>
          <Box
            onClick={() => setMoreOpen(!moreOpen)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: sidebarExpanded ? 'flex-start' : 'center',
              gap: 2,
              px: sidebarExpanded ? 1 : 0,
              minHeight: 40,
              cursor: 'pointer',
              transition: 'all 0.2s ease',
              '&:hover': {
                '& .MuiSvgIcon-root': {
                  color: 'warning.main',
                  transform: 'scale(1.1)',
                },
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {sidebarExpanded && (
                <Typography
                  sx={{
                    transition: 'opacity 0.2s ease',
                    whiteSpace: 'nowrap',
                    mr: 1,
                  }}
                >
                  More
                </Typography>
              )}
              <ExpandMore
                sx={{
                  transform: moreOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s',
                }}
              />
            </Box>
          </Box>
          <Collapse in={moreOpen} timeout="auto" unmountOnExit>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Link 
                to="https://apps.apple.com/us/app/cowboyquote/id6737703038" 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ textDecoration: 'none', color: 'white' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: sidebarExpanded ? 'flex-start' : 'center',
                    gap: 2,
                    px: sidebarExpanded ? 1 : 0,
                    minHeight: 40,
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      '& .MuiSvgIcon-root': {
                        color: 'warning.main',
                        transform: 'scale(1.1)',
                      },
                    },
                  }}
                >
                  <IconButton
                    color="inherit"
                    sx={{
                      width: 40,
                      height: 40,
                      mr: sidebarExpanded ? 1 : 0,
                    }}
                  >
                    <Apple />
                  </IconButton>
                  {sidebarExpanded && (
                    <Typography variant="body2">
                      Download App
                    </Typography>
                  )}
                </Box>
              </Link>

              <Link to="/dashboard/trail-guide" style={{ textDecoration: 'none', color: 'white' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: sidebarExpanded ? 'flex-start' : 'center',
                    gap: 2,
                    px: sidebarExpanded ? 1 : 0,
                    minHeight: 40,
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      '& .MuiSvgIcon-root': {
                        color: 'warning.main',
                        transform: 'scale(1.1)',
                      },
                    },
                  }}
                >
                  <IconButton
                    color="inherit"
                    sx={{
                      width: 40,
                      height: 40,
                      mr: sidebarExpanded ? 1 : 0,
                    }}
                  >
                    <AutoStoriesIcon />
                  </IconButton>
                  {sidebarExpanded && (
                    <Typography variant="body2">
                      Trail Guide
                    </Typography>
                  )}
                </Box>
              </Link>

              <Link to="/dashboard/supplies" style={{ textDecoration: 'none', color: 'white' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: sidebarExpanded ? 'flex-start' : 'center',
                    gap: 2,
                    px: sidebarExpanded ? 1 : 0,
                    minHeight: 40,
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      '& .MuiSvgIcon-root': {
                        color: 'warning.main',
                        transform: 'scale(1.1)',
                      },
                    },
                  }}
                >
                  <IconButton
                    color="inherit"
                    sx={{
                      width: 40,
                      height: 40,
                      mr: sidebarExpanded ? 1 : 0,
                    }}
                  >
                    <ShoppingBagIcon />
                  </IconButton>
                  {sidebarExpanded && (
                    <Typography variant="body2">
                      Get Supplies
                    </Typography>
                  )}
                </Box>
              </Link>

              <Link to="/logout" style={{ textDecoration: 'none', color: 'white' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: sidebarExpanded ? 'flex-start' : 'center',
                    gap: 2,
                    px: sidebarExpanded ? 1 : 0,
                    minHeight: 40,
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      '& .MuiSvgIcon-root': {
                        color: 'warning.main',
                        transform: 'scale(1.1)',
                      },
                    },
                  }}
                >
                  <IconButton
                    color="inherit"
                    sx={{
                      width: 40,
                      height: 40,
                      mr: sidebarExpanded ? 1 : 0,
                    }}
                  >
                    <LogoutIcon />
                  </IconButton>
                  {sidebarExpanded && (
                    <Typography variant="body2">
                      Logout
                    </Typography>
                  )}
                </Box>
              </Link>
            </Box>
          </Collapse>
        </Box>

        {/* Dark mode toggle */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: sidebarExpanded ? 'flex-start' : 'center',
            width: '100%',
            pt: 2,
            borderTop: 1,
            borderColor: 'rgba(255, 255, 255, 0.12)',
          }}
        >
          <IconButton
            onClick={colorMode.toggleColorMode}
            color="inherit"
            sx={{
              width: 40,
              height: 40,
              mr: sidebarExpanded ? 1 : 0,
            }}
          >
            {theme.palette.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
          </IconButton>
          {sidebarExpanded && (
            <Typography variant="body2" sx={{ flexGrow: 1 }}>
              {theme.palette.mode === 'dark' ? 'Light Mode' : 'Dark Mode'}
            </Typography>
          )}
        </Box>
      </Box>

      {/* Main content */}
      <Box sx={{ flexGrow: 1, p: 3, backgroundColor: 'background.default', overflowY: 'auto' }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2
        }}>
          <UserNotifications />
          <CreateNew />
        </Box>

        <Routes>
          <Route path="/" element={<DashboardHome />} />
          <Route path="/quotes" element={<Quotes />} />
          <Route path="/templates" element={<Templates />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/campaigns" element={<CampaignsList />} />
          <Route path="/campaigns/:campaignId" element={<CampaignDetails />} />
          <Route path="/theroundup" element={<TheRoundup />} />
          <Route path="/supplies" element={<GetSupplies />} />
          <Route path="/trail-guide" element={<TrailGuide />} />
          <Route path="/subscription" element={<SubscriptionManager />} />
          <Route path="/all-quotes" element={<ViewAllQuotes />} />
          <Route path="/predefined-services" element={<PredefinedServicesPage />} />
          <Route path="/internal/quote/:quoteId" element={<ViewQuote />} />
          <Route path="/users/:userId" element={<ViewUser />} />
        </Routes>

        <TermsOfServiceModal onAcceptComplete={handleTosAcceptComplete} />
        {tosAccepted && (
          <WelcomeModal
            open={showWelcomeModal}
            onLocationSet={handleLocationSet}
          />
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;