import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Cell
} from 'recharts';
import { Card, CardContent, Typography, Box, Button, CircularProgress } from '@mui/material';
import { Person, Timeline, Visibility } from '@mui/icons-material';
import { alpha } from '@mui/material/styles';
import { useAuth } from '../AuthContext';
import axiosInstance from '../utils/axiosConfig';
import { useSnackbar } from 'notistack';
import UserChip from './UserChip';

interface AuthUser {
  role?: string;
}

interface UserPerformance {
  userId: string;
  name: string;
  userColor: string;
  performance: {
    quoteCount: number;
    acceptedQuoteCount: number;
    viewedQuoteCount: number;
  };
}

interface ChartData {
  userId: string;
  name: string;
  value: number;
  userColor: string;
}

interface Achievement {
  type: 'MOST_ACTIVE' | 'SHARPSHOOTER' | 'EAGLE_EYE'; 
  displayName: string;
  description: string;
  icon: React.ReactNode;
  currentLeader?: {
    userId: string;
    userName: string;
    userColor: string;
    value: number;
  };
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{
    value: number;
    payload: ChartData;
    unit?: string;
  }>;
}

const TheRoundup: React.FC = () => {
  const { user } = useAuth() as { user: AuthUser | null };
  const { enqueueSnackbar } = useSnackbar();
  
  const [users, setUsers] = useState<UserPerformance[]>([]);
  const [loading, setLoading] = useState(true);

  // 1) Updated achievements array: EAGLE_EYE for “Most Viewed Quotes”
  const [achievements, setAchievements] = useState<Achievement[]>([
    {
      type: 'MOST_ACTIVE',
      displayName: 'Most Active Cowboy',
      description: 'Highest number of quotes this month',
      icon: <Person sx={{ fontSize: 40 }} />,
    },
    {
      type: 'SHARPSHOOTER',
      displayName: 'Sharpshooter',
      description: 'Best acceptance rate this month',
      icon: <Timeline sx={{ fontSize: 40 }} />,
    },
    {
      type: 'EAGLE_EYE',
      displayName: 'Eagle Eye',
      description: 'Most quotes viewed this month',
      icon: <Visibility sx={{ fontSize: 40 }} />,
    },
  ]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const endpoint = user?.role === 'Owner' ? '/api/users/company' : '/api/users/company-metrics';
      const response = await axiosInstance.get(endpoint);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      enqueueSnackbar('Error fetching user data', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleDistributeAchievements = async () => {
    try {
      await axiosInstance.post('/api/achievements/distribute');
      enqueueSnackbar('Monthly achievements distributed successfully!', { variant: 'success' });
      fetchUsers(); // refresh data after distribution
    } catch (error) {
      console.error('Error distributing achievements:', error);
      enqueueSnackbar('Failed to distribute achievements', { variant: 'error' });
    }
  };

  // 2) Recalculate achievements whenever users change
  useEffect(() => {
    const updatedAchievements = achievements.map((achievement) => {
      if (users.length === 0) return achievement;

      let currentLeader;
      switch (achievement.type) {
        case 'MOST_ACTIVE': {
          // Highest quoteCount
          currentLeader = users.reduce((prev, current) =>
            current.performance.quoteCount > (prev?.performance.quoteCount || 0)
              ? current
              : prev
          );
          return {
            ...achievement,
            currentLeader: currentLeader
              ? {
                  userId: currentLeader.userId,
                  userName: currentLeader.name,
                  userColor: currentLeader.userColor,
                  value: currentLeader.performance.quoteCount,
                }
              : undefined,
          };
        }

        case 'SHARPSHOOTER': {
          // Best acceptance rate = acceptedQuoteCount / quoteCount
          currentLeader = users.reduce((prev, current) => {
            const currentRate =
              current.performance.quoteCount > 0
                ? (current.performance.acceptedQuoteCount / current.performance.quoteCount) * 100
                : 0;
            const prevRate =
              prev?.performance.quoteCount > 0
                ? (prev.performance.acceptedQuoteCount / prev.performance.quoteCount) * 100
                : 0;
            return currentRate > prevRate ? current : prev;
          });
          return {
            ...achievement,
            currentLeader: currentLeader
              ? {
                  userId: currentLeader.userId,
                  userName: currentLeader.name,
                  userColor: currentLeader.userColor,
                  value:
                    currentLeader.performance.quoteCount > 0
                      ? (currentLeader.performance.acceptedQuoteCount / currentLeader.performance.quoteCount) * 100
                      : 0,
                }
              : undefined,
          };
        }

        case 'EAGLE_EYE': {
          // Most viewed quotes = highest viewedQuoteCount
          currentLeader = users.reduce((prev, current) =>
            current.performance.viewedQuoteCount > (prev?.performance.viewedQuoteCount || 0)
              ? current
              : prev
          );
          return {
            ...achievement,
            currentLeader: currentLeader
              ? {
                  userId: currentLeader.userId,
                  userName: currentLeader.name,
                  userColor: currentLeader.userColor,
                  value: currentLeader.performance.viewedQuoteCount,
                }
              : undefined,
          };
        }

        default:
          return achievement;
      }
    });

    setAchievements(updatedAchievements);
  }, [users, achievements]);

  // 3) Prepare chart data (removed averageResponseTime references)
  const getChartData = (achievementType: Achievement['type']): ChartData[] => {
    return users
      .map((u) => {
        let value = 0;
        switch (achievementType) {
          case 'MOST_ACTIVE':
            value = u.performance.quoteCount;
            break;
          case 'SHARPSHOOTER':
            value =
              u.performance.quoteCount > 0
                ? (u.performance.acceptedQuoteCount / u.performance.quoteCount) * 100
                : 0;
            break;
          case 'EAGLE_EYE':
            value = u.performance.viewedQuoteCount;
            break;
        }
        return {
          userId: u.userId,
          name: u.name,
          value,
          userColor: u.userColor,
        };
      })
      .sort((a, b) =>
        // For EAGLE_EYE, we want the highest view count first
        achievementType === 'SHARPSHOOTER'
          ? b.value - a.value // sort descending for rates
          : b.value - a.value // also descending for other metrics
      );
  };

  const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      const { name } = payload[0].payload;
      const { value, unit } = payload[0];
      return (
        <Box
          sx={{
            backgroundColor: 'background.paper',
            p: 1.5,
            border: 1,
            borderColor: 'divider',
            borderRadius: 1,
          }}
        >
          <Typography variant="subtitle2">{name}</Typography>
          <Typography variant="body2">
            {unit === '%' ? `${value.toFixed(1)}%` : value}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  const renderLabel = (achievementType: Achievement['type'], value: number) => {
    switch (achievementType) {
      case 'SHARPSHOOTER':
        return `${value.toFixed(1)}%`;
      // For EAGLE_EYE or MOST_ACTIVE, just show integer
      default:
        return value.toString();
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (users.length === 0) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6">No user data available.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      {/* Title + Distribute Button */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4, mt:4 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          The Roundup
        </Typography>
        {user?.role === 'Owner' && (
          <Button
            variant="contained"
            size="small"
            color="warning"
            onClick={handleDistributeAchievements}
            sx={{ textTransform: 'none', width: 200 }}
          >
            Distribute Monthly Achievements
          </Button>
        )}
      </Box>

      {/* Achievements */}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
        {achievements.map((achievement) => {
          const data = getChartData(achievement.type);
          return (
            <Card
              key={achievement.type}
              sx={{
                flex: 1,
                minWidth: 300,
                position: 'relative',
                overflow: 'visible',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': { transform: 'translateY(-4px)' },
              }}
            >
              <CardContent>
                {/* Header */}
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Box
                    sx={{
                      backgroundColor: (theme) => alpha(theme.palette.warning.main, 0.1),
                      borderRadius: 1,
                      p: 1,
                      mr: 2,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {achievement.icon}
                  </Box>
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                      {achievement.displayName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {achievement.description}
                    </Typography>
                  </Box>
                </Box>

                {/* Current Leader */}
                {achievement.currentLeader && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Current Leader:
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <UserChip
                        userId={achievement.currentLeader.userId}
                        userName={achievement.currentLeader.userName}
                        userColor={achievement.currentLeader.userColor}
                      />
                      <Typography variant="body2">
                        {renderLabel(achievement.type, achievement.currentLeader.value)}
                      </Typography>
                    </Box>
                  </Box>
                )}

                {/* Chart */}
                <Box sx={{ height: 200 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={data} margin={{ top: 20, right: 10, left: -20, bottom: 0 }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" hide />
                      <YAxis />
                      <Tooltip content={<CustomTooltip />} />
                      <Bar dataKey="value" fill="#8884d8">
                        {data.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.userColor || '#666666'} />
                        ))}
                        <LabelList
                          dataKey="value"
                          position="top"
                          formatter={(val: number) => renderLabel(achievement.type, val)}
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
          );
        })}
      </Box>
    </Box>
  );
};

export default TheRoundup;