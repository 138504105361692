// frontend/src/components/Settings.tsx

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Tabs,
  Tab,
  useTheme,
  useMediaQuery,
  Divider,
  Paper,
  Autocomplete,
  CircularProgress as MuiCircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControlLabel,
  Checkbox,
  Snackbar
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import axiosInstance from '../utils/axiosConfig';
import { getDecodedToken } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { debounce } from 'lodash';
import CompanyUsers from './CompanyUsers';
import PasswordChangeForm from './PasswordChangeForm';

// --------------------
// Interfaces and Defaults
// --------------------
interface CompanySettings {
  companyName: string;
  companyPhoneNumber: string;
  companyLogo: string | null;
  planTier: string;
  whoWeAreText: string;
  googleMapEmbed: string;
  websiteLink: string;
  compLocation: {
    latitude: number;
    longitude: number;
  } | null;
  // Generic CRM Integration fields:
  crmIntegrationEnabled?: boolean;
  crmIntegrationSnippet?: string;
  crmFormTemplateId?: string;
  crmCompanyID?: string;
  crmProvider?: string;
}

interface UserSettings {
  whoIAmText: string;
}

interface LocationSuggestion {
  display_name: string;
  lat: string;
  lon: string;
}

const defaultCompanySettings: CompanySettings = {
  companyName: '',
  companyPhoneNumber: '',
  companyLogo: null,
  planTier: 'Free',
  whoWeAreText: '',
  googleMapEmbed: '',
  websiteLink: '',
  compLocation: null,
  crmIntegrationEnabled: false,
  crmIntegrationSnippet: '',
  crmFormTemplateId: '',
  crmCompanyID: '',
  crmProvider: '',
};

const defaultUserSettings: UserSettings = {
  whoIAmText: '',
};

// --------------------
// Styled Components
// --------------------
const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(4),
}));

interface TabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
  return value === index ? (
    <div role="tabpanel" id={`settings-tabpanel-${index}`} aria-labelledby={`settings-tab-${index}`}>
      {children}
    </div>
  ) : null;
};

// --------------------
// Helper Function: Parse CRM Integration Snippet from Source Code
// (This function extracts the Company ID from a snippet.)
function parseSAIntegrationSnippet(snippet: string): { saFormTemplateId: string; saCompanyID: string } {
  let saFormTemplateId = '';
  let saCompanyID = '';

  // First, extract the template ID from the snippet (if present)
  const idRegex = /[?&]id=([0-9a-fA-F\-]+)/;
  const idMatch = snippet.match(idRegex);
  if (idMatch && idMatch[1]) {
    saFormTemplateId = idMatch[1];
  }

  // Try a regex for a typical header format (e.g. x-CompanyID: "...")
  let companyIdRegex = /x-CompanyID\s*[:=]\s*['"]?([0-9a-fA-F\-]+)/i;
  let companyIdMatch = snippet.match(companyIdRegex);
  
  // If not found, try the setRequestHeader format
  if (!(companyIdMatch && companyIdMatch[1])) {
    companyIdRegex = /setRequestHeader\s*\(\s*['"]x-CompanyID['"]\s*,\s*['"]([0-9a-fA-F\-]+)['"]\s*\)/i;
    companyIdMatch = snippet.match(companyIdRegex);
  }
  
  if (companyIdMatch && companyIdMatch[1]) {
    saCompanyID = companyIdMatch[1];
  }

  return { saFormTemplateId, saCompanyID };
}

// --------------------
// Main Component: Settings
// --------------------
const Settings: React.FC = () => {
  const decodedToken = getDecodedToken();
  const userRole = decodedToken?.role || '';
  const userId = decodedToken?.userId || '';

  // --------------------
  // State Declarations
  // --------------------
  // Company Settings & User Settings
  const [companySettings, setCompanySettings] = useState<CompanySettings>(defaultCompanySettings);
  const [companyLoading, setCompanyLoading] = useState<boolean>(true);
  const [companyError, setCompanyError] = useState<string | null>(null);
  const [companySuccess, setCompanySuccess] = useState<string | null>(null);
  const [logoFile, setLogoFile] = useState<File | null>(null);

  const [userSettings, setUserSettings] = useState<UserSettings>(defaultUserSettings);
  const [userLoading, setUserLoading] = useState<boolean>(true);
  const [userError, setUserError] = useState<string | null>(null);
  const [userSuccess, setUserSuccess] = useState<string | null>(null);

  const [planName, setPlanName] = useState<string>('Free');

  // Base Location States
  const [baseLocation, setBaseLocation] = useState<{ displayName: string; latitude: number; longitude: number; } | null>(null);
  const [locationInput, setLocationInput] = useState<string>('');
  const [locationOptions, setLocationOptions] = useState<LocationSuggestion[]>([]);
  const [locationLoading, setLocationLoading] = useState<boolean>(false);
  const [locationError, setLocationError] = useState<string | null>(null);
  const [locationSuccess, setLocationSuccess] = useState<string | null>(null);

  // External CRM Integration Modal States
  const [crmModalOpen, setCrmModalOpen] = useState<boolean>(false);
  const [crmModalStep, setCrmModalStep] = useState<number>(0);
  const [selectedCRM, setSelectedCRM] = useState<string>(''); // e.g., 'Service Autopilot'
  // For page 2: manual input for the Form Template ID and paste Source Code for parsing Company ID.
  const [modalFormTemplateId, setModalFormTemplateId] = useState<string>('');
  const [modalSourceCode, setModalSourceCode] = useState<string>('');
  // Parsed values from the modal:
  const [modalParsedData, setModalParsedData] = useState<{ saFormTemplateId: string; saCompanyID: string }>({
    saFormTemplateId: '',
    saCompanyID: '',
  });
  const [parsingInProgress, setParsingInProgress] = useState<boolean>(false);
  const [parsingSuccess, setParsingSuccess] = useState<boolean>(false);

  // Snackbar for confirmation messages
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  // Tab handling
  // For Owners, we use four tabs; for non-owners, only user settings.
  interface OwnerTabValues {
    COMPANY_SETTINGS: number;
    COMPANY_USERS: number;
    EXTERNAL_CRM: number;
    USER_SETTINGS: number;
  }
  interface NonOwnerTabValues {
    USER_SETTINGS: number;
  }
  const TAB_VALUES: OwnerTabValues | NonOwnerTabValues = userRole === 'Owner'
    ? { COMPANY_SETTINGS: 0, COMPANY_USERS: 1, EXTERNAL_CRM: 2, USER_SETTINGS: 3 }
    : { USER_SETTINGS: 0 };

  const getInitialTab = (): number => {
    if (userRole === 'Owner') {
      return (TAB_VALUES as OwnerTabValues).COMPANY_SETTINGS;
    }
    return (TAB_VALUES as NonOwnerTabValues).USER_SETTINGS;
  };
  const [activeTab, setActiveTab] = useState<number>(getInitialTab());

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  // --------------------
  // Effects for Fetching Settings and Snackbar
  // --------------------
  useEffect(() => {
    if (userRole) {
      if (userRole === 'Owner') {
        fetchCompanySettings();
      }
      fetchUserSettings();
    }
  }, [userRole]);

  useEffect(() => {
    if (companySuccess) {
      setSnackbarOpen(true);
      const timer = setTimeout(() => {
        setSnackbarOpen(false);
        setCompanySuccess(null);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [companySuccess]);

  useEffect(() => {
    if (userSuccess) {
      const timer = setTimeout(() => {
        setUserSuccess(null);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [userSuccess]);

  // --------------------
  // Data Fetching Functions
  // --------------------
  const fetchCompanySettings = async () => {
    try {
      const response = await axiosInstance.get('/api/compSettings');
      setCompanySettings({
        companyName: response.data.companyName ?? '',
        companyPhoneNumber: response.data.companyPhoneNumber ?? '',
        companyLogo: response.data.companyLogo ?? null,
        planTier: response.data.planTier ?? 'Free',
        whoWeAreText: response.data.whoWeAreText ?? '',
        googleMapEmbed: response.data.googleMapEmbed ?? '',
        websiteLink: response.data.websiteLink ?? '',
        compLocation: response.data.compLocation ?? null,
        crmIntegrationEnabled: response.data.crmIntegrationEnabled || false,
        crmIntegrationSnippet: response.data.crmIntegrationSnippet || '',
        crmFormTemplateId: response.data.crmFormTemplateId || '',
        crmCompanyID: response.data.crmCompanyID || '',
        crmProvider: response.data.crmProvider || '',
      });
      if (response.data.compLocation) {
        setBaseLocation({
          displayName: '', // Optionally, set a proper display name
          latitude: response.data.compLocation.latitude,
          longitude: response.data.compLocation.longitude,
        });
      }
      if (response.data.planTier && response.data.planTier !== 'Free') {
        try {
          const planResponse = await axiosInstance.get(`/api/plans/${response.data.planTier}`);
          if (planResponse.data && planResponse.data.name) {
            setPlanName(planResponse.data.name);
          } else {
            setPlanName('Free');
          }
        } catch (error: any) {
          console.error('Error fetching plan name:', error);
          setPlanName('Unknown Plan');
        }
      } else {
        setPlanName('Free');
      }
      setCompanyLoading(false);
    } catch (error: any) {
      console.error('Error fetching company settings:', error);
      setCompanyError('Failed to load company settings. Please try again.');
      setCompanyLoading(false);
    }
  };

  const fetchUserSettings = async () => {
    try {
      const response = await axiosInstance.get(`/api/users/${userId}`);
      setUserSettings({
        whoIAmText: response.data.whoIAmText ?? '',
      });
      setUserLoading(false);
    } catch (error: any) {
      console.error('Error fetching user settings:', error);
      setUserError('Failed to load user settings. Please try again.');
      setUserLoading(false);
    }
  };

  // --------------------
  // Handlers for Company Settings Form
  // --------------------
  const handleCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setCompanySettings({
      ...companySettings,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setLogoFile(e.target.files[0]);
    }
  };

  const handleCompanySubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setCompanyLoading(true);
    setCompanyError(null);
    setCompanySuccess(null);
    try {
      const formData = new FormData();
      formData.append('companyName', companySettings.companyName);
      formData.append('companyPhoneNumber', companySettings.companyPhoneNumber);
      formData.append('planTier', companySettings.planTier);
      formData.append('whoWeAreText', companySettings.whoWeAreText);
      formData.append('googleMapEmbed', companySettings.googleMapEmbed);
      formData.append('websiteLink', companySettings.websiteLink);
      if (logoFile) {
        formData.append('companyLogo', logoFile);
      }
      const response = await axiosInstance.put('/api/compSettings', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setCompanySettings({
        companyName: response.data.companyName ?? '',
        companyPhoneNumber: response.data.companyPhoneNumber ?? '',
        companyLogo: response.data.companyLogo ?? null,
        planTier: response.data.planTier ?? 'Free',
        whoWeAreText: response.data.whoWeAreText ?? '',
        googleMapEmbed: response.data.googleMapEmbed ?? '',
        websiteLink: response.data.websiteLink ?? '',
        compLocation: response.data.compLocation ?? null,
        crmIntegrationEnabled: response.data.crmIntegrationEnabled || false,
        crmIntegrationSnippet: response.data.crmIntegrationSnippet || '',
        crmFormTemplateId: response.data.crmFormTemplateId || '',
        crmCompanyID: response.data.crmCompanyID || '',
        crmProvider: response.data.crmProvider || '',
      });
      if (response.data.compLocation) {
        setBaseLocation({
          displayName: '',
          latitude: response.data.compLocation.latitude,
          longitude: response.data.compLocation.longitude,
        });
      }
      if (response.data.planTier && response.data.planTier !== 'Free') {
        try {
          const planResponse = await axiosInstance.get(`/api/plans/${response.data.planTier}`);
          setPlanName(planResponse.data.name);
        } catch (error) {
          console.error('Error fetching plan name:', error);
          setPlanName('Unknown Plan');
        }
      } else {
        setPlanName('Free');
      }
      setCompanySuccess('Company settings updated successfully');
      setLogoFile(null);
    } catch (error: any) {
      console.error('Error updating company settings:', error);
      setCompanyError('Failed to update company settings. Please try again.');
    } finally {
      setCompanyLoading(false);
    }
  };

  // --------------------
  // Handlers for User Settings Form
  // --------------------
  const handleUserChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserSettings({ ...userSettings, [e.target.name]: e.target.value });
  };

  const handleUserSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setUserLoading(true);
    setUserError(null);
    setUserSuccess(null);
    try {
      const response = await axiosInstance.put(`/api/users/update/${userId}`, userSettings);
      setUserSettings({
        whoIAmText: response.data.whoIAmText ?? '',
      });
      setUserSuccess('User settings updated successfully');
    } catch (error: any) {
      console.error('Error updating user settings:', error);
      if (error.response) {
        setUserError(error.response.data.message || 'Failed to update user settings. Please try again.');
      } else if (error.request) {
        setUserError('No response from server. Please check your network connection.');
      } else {
        setUserError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setUserLoading(false);
    }
  };

  // --------------------
  // Handlers for Location (unchanged)
  // --------------------
  const fetchCitySuggestions = async (query: string) => {
    if (query.length < 3) return;
    try {
      setLocationLoading(true);
      const response = await axiosInstance.get('/api/locationiq/cities', {
        params: { q: query },
      });
      setLocationOptions(response.data);
    } catch (error: any) {
      console.error('Error fetching city suggestions:', error);
      setLocationError('Failed to fetch city suggestions.');
      setLocationOptions([]);
    } finally {
      setLocationLoading(false);
    }
  };

  const debouncedFetchCitySuggestions = useCallback(
    debounce((query: string) => fetchCitySuggestions(query), 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedFetchCitySuggestions.cancel();
    };
  }, [debouncedFetchCitySuggestions]);

  const handleLocationSelect = (event: React.SyntheticEvent<Element, Event>, newValue: LocationSuggestion | null) => {
    if (newValue) {
      const { display_name, lat, lon } = newValue;
      setBaseLocation({
        displayName: display_name,
        latitude: parseFloat(lat),
        longitude: parseFloat(lon),
      });
    }
  };

  const handleLocationSubmit = async () => {
    if (!baseLocation) return;
    setLocationLoading(true);
    setLocationError(null);
    setLocationSuccess(null);
    try {
      const response = await axiosInstance.post('/api/compSettings/setLocation', {
        latitude: baseLocation.latitude,
        longitude: baseLocation.longitude,
      });
      setCompanySettings((prev) => ({
        ...prev,
        compLocation: response.data.compLocation,
      }));
      setLocationSuccess('Base location updated successfully.');
    } catch (error: any) {
      console.error('Error updating base location:', error);
      setLocationError('Failed to update base location. Please try again.');
    } finally {
      setLocationLoading(false);
    }
  };

  // --------------------
  // Handlers for External CRM Integration Modal
  // --------------------
  const availableCRMs = [{ id: 'sa', name: 'Service Autopilot' }];

  const handleOpenCrmModal = () => {
    setCrmModalStep(0);
    setSelectedCRM('');
    // Reset the fields for page 2
    setModalFormTemplateId('');
    setModalSourceCode('');
    setModalParsedData({ saFormTemplateId: '', saCompanyID: '' });
    setParsingInProgress(false);
    setParsingSuccess(false);
    setCrmModalOpen(true);
  };

  const handleCloseCrmModal = () => {
    setCrmModalOpen(false);
  };

  const handleCrmNext = () => {
    setCrmModalStep((prev) => prev + 1);
  };

  const handleCrmPrevious = () => {
    setCrmModalStep((prev) => prev - 1);
  };

  // --------------------
  // Modal Page 2: Manual Form Template ID + Parse Source Code for Company ID
  // --------------------
  const handleParseSnippet = async () => {
    if (!modalSourceCode.trim()) {
      console.log("Source Code field is required for parsing the Company ID.");
      return;
    }
    console.log("Parsing Source Code for Company ID:", modalSourceCode);
    setParsingInProgress(true);
    setTimeout(() => {
      const parsedSource = parseSAIntegrationSnippet(modalSourceCode);
      const parsedCompanyId = parsedSource.saCompanyID;
      console.log("Parsed Company ID:", parsedCompanyId);
      // We leave the manually entered form template ID as is.
      setModalParsedData({
        saFormTemplateId: modalFormTemplateId,
        saCompanyID: parsedCompanyId,
      });
      setParsingInProgress(false);
      const success = Boolean(parsedCompanyId);
      setParsingSuccess(success);
      if (!success) {
        console.warn("Parsing did not find a Company ID.");
      }
    }, 1500);
  };

  const handleConfirmCrmConnection = async () => {
    console.log("Confirm connection clicked. Preparing to send payload...");
    try {
      const payload = {
        crmIntegrationEnabled: true,
        crmIntegrationSnippet: modalFormTemplateId + "\n" + modalSourceCode,
        crmFormTemplateId: modalFormTemplateId,
        crmCompanyID: modalParsedData.saCompanyID,
        crmProvider: selectedCRM, // e.g. 'Service Autopilot'
      };
      console.log("Payload to send:", payload);
      const response = await axiosInstance.put('/api/compSettings/externalCRM', payload);
      console.log("Response from externalCRM route:", response.data);
      await fetchCompanySettings(); // Refresh settings so that the connection details are visible.
      setCompanySuccess('External CRM connected successfully.');
      setCrmModalOpen(false);
    } catch (error: any) {
      console.error('Error connecting external CRM:', error);
      setCompanyError('Failed to connect external CRM. Please try again.');
    }
  };

  // --------------------
  // Snackbar Handler
  // --------------------
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // --------------------
  // Render
  // --------------------
  if ((userRole === 'Owner' && companyLoading) || userLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)', bgcolor: 'background.default' }}>
      <Box sx={{ width: '100%', p: 2, overflowY: 'auto' }}>
        <Typography variant="h4" gutterBottom align="center">
          Settings
        </Typography>

        {/* Tabs Section */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            sx={{ mb: 3, '& .MuiTabs-indicator': { backgroundColor: 'warning.main' } }}
            textColor="inherit"
          >
            {userRole === 'Owner' && (
              <Tab
                label="Company Settings"
                sx={(theme) => ({
                  color: 'warning.main',
                  '&.Mui-selected': { color: 'warning.main' },
                  '&:hover': { color: alpha(theme.palette.common.white, 1), backgroundColor: alpha(theme.palette.warning.main, 0.8), cursor: 'pointer' },
                })}
              />
            )}
            {userRole === 'Owner' && (
              <Tab
                label="Manage Cowboys"
                sx={(theme) => ({
                  color: 'warning.main',
                  '&.Mui-selected': { color: 'warning.main' },
                  '&:hover': { color: alpha(theme.palette.common.white, 1), backgroundColor: alpha(theme.palette.warning.main, 0.8), cursor: 'pointer' },
                })}
              />
            )}
            {userRole === 'Owner' && (
              <Tab
                label="External CRM Integration"
                sx={(theme) => ({
                  color: 'warning.main',
                  '&.Mui-selected': { color: 'warning.main' },
                  '&:hover': { color: alpha(theme.palette.common.white, 1), backgroundColor: alpha(theme.palette.warning.main, 0.8), cursor: 'pointer' },
                })}
              />
            )}
            <Tab
              label="My User Settings"
              sx={(theme) => ({
                color: 'warning.main',
                '&.Mui-selected': { color: 'warning.main' },
                '&:hover': { color: alpha(theme.palette.common.white, 1), backgroundColor: alpha(theme.palette.warning.main, 0.8), cursor: 'pointer' },
              })}
            />
          </Tabs>
        </Box>

        {/* Tab Panels */}
        <Box sx={{ flexGrow: 1, p: isMobile ? 2 : 4 }}>
          {userRole === 'Owner' && (
            <TabPanel value={activeTab} index={(TAB_VALUES as any).COMPANY_SETTINGS}>
              <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="h5" gutterBottom>
                  Company Settings
                </Typography>
                <Divider sx={{ mb: 3 }} />
                {companyError && <Alert severity="error" sx={{ mb: 2 }}>{companyError}</Alert>}
                {companySuccess && <Alert severity="success" sx={{ mb: 2 }}>{companySuccess}</Alert>}
                <form onSubmit={handleCompanySubmit}>
                  <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2 }}>
                    <TextField
                      label="Company Name"
                      name="companyName"
                      value={companySettings.companyName}
                      onChange={handleCompanyChange}
                      required
                      fullWidth
                      disabled
                    />
                    <TextField
                      label="Company Phone Number"
                      name="companyPhoneNumber"
                      value={companySettings.companyPhoneNumber}
                      onChange={handleCompanyChange}
                      fullWidth
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': { borderColor: 'warning.main' },
                        },
                        '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                      }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2, mt: 2 }}>
                    <TextField label="Plan Tier" name="planTier" value={planName} fullWidth disabled />
                    <Button
                      variant="contained"
                      color="info"
                      onClick={() => navigate('/dashboard/subscription')}
                      sx={{ height: '56px' }}
                    >
                      Manage Subscription
                    </Button>
                  </Box>
                  <TextField
                    label="Who We Are"
                    name="whoWeAreText"
                    value={companySettings.whoWeAreText}
                    onChange={handleCompanyChange}
                    margin="normal"
                    multiline
                    rows={4}
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { borderColor: 'warning.main' } },
                      '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                    }}
                  />
                  <TextField
                    label="Google Map Embed"
                    name="googleMapEmbed"
                    value={companySettings.googleMapEmbed}
                    onChange={handleCompanyChange}
                    margin="normal"
                    multiline
                    rows={2}
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { borderColor: 'warning.main' } },
                      '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                    }}
                  />
                  <TextField
                    label="Website Link"
                    name="websiteLink"
                    value={companySettings.websiteLink}
                    onChange={handleCompanyChange}
                    margin="normal"
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { borderColor: 'warning.main' } },
                      '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                    }}
                  />
                  <Box sx={{ mt: 3, mb: 3 }}>
                    <input accept="image/*" style={{ display: 'none' }} id="logo-file" type="file" onChange={handleLogoChange} />
                    <label htmlFor="logo-file">
                      <Button
                        variant="outlined"
                        component="span"
                        sx={{
                          color: 'warning.main',
                          borderColor: 'warning.main',
                          '&:hover': { borderColor: 'warning.dark', color: 'warning.dark' },
                        }}
                      >
                        Upload Logo
                      </Button>
                    </label>
                    {logoFile && <Typography variant="body2" sx={{ ml: 2, display: 'inline' }}>{logoFile.name}</Typography>}
                  </Box>
                  {companySettings.companyLogo && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 4 }}>
                      <Typography variant="subtitle1" sx={{ mr: 2 }}>Current Logo:</Typography>
                      <img
                        src={companySettings.companyLogo.replace(/^\//, '')}
                        alt="Company Logo"
                        style={{ maxWidth: '200px', height: 'auto' }}
                      />
                    </Box>
                  )}
                  <Button type="submit" variant="contained" color="warning" fullWidth={!isMobile} sx={{ mt: 2 }}>
                    Save Company Settings
                  </Button>
                </form>
                {/* Base Location Section */}
                <Box sx={{ mt: 6 }}>
                  <Typography variant="h6" gutterBottom>
                    Base Location
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Autocomplete
                      options={locationOptions}
                      getOptionLabel={(option: LocationSuggestion) => option.display_name}
                      onInputChange={(event, newInputValue) => {
                        setLocationInput(newInputValue);
                        if (newInputValue.length >= 3) {
                          debouncedFetchCitySuggestions(newInputValue);
                        } else {
                          setLocationOptions([]);
                        }
                      }}
                      onChange={handleLocationSelect}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search for a city"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {locationLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      sx={{
                        width: '100%',
                        mr: 2,
                        '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { borderColor: 'warning.main' } },
                        '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                      }}
                      disableClearable
                    />
                    <Button
                      variant="contained"
                      color="info"
                      onClick={handleLocationSubmit}
                      disabled={locationLoading || !baseLocation}
                      sx={{ height: '56px', flexGrow: 1 }}
                    >
                      {locationLoading ? <CircularProgress size={24} /> : 'Update Base Location'}
                    </Button>
                  </Box>
                  {locationError && <Alert severity="error" sx={{ mb: 2 }}>{locationError}</Alert>}
                  {locationSuccess && <Alert severity="success" sx={{ mb: 2 }}>{locationSuccess}</Alert>}
                  {baseLocation && (
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      Selected Location: <strong>{baseLocation.displayName || 'N/A'}</strong>
                    </Typography>
                  )}
                  {companySettings.compLocation && (
                    <Typography variant="body1">
                      Current Base Location: <strong>
                        Latitude: {companySettings.compLocation.latitude}, Longitude: {companySettings.compLocation.longitude}
                      </strong>
                    </Typography>
                  )}
                </Box>
              </Paper>
            </TabPanel>
          )}
          {userRole === 'Owner' && (
            <TabPanel value={activeTab} index={(TAB_VALUES as any).COMPANY_USERS}>
              <Paper elevation={3} sx={{ p: 4 }}>
                <CompanyUsers />
              </Paper>
            </TabPanel>
          )}
          {userRole === 'Owner' && (
            <TabPanel value={activeTab} index={(TAB_VALUES as any).EXTERNAL_CRM}>
              <Paper elevation={3} sx={{ p: 4, textAlign: 'center' }}>
                <Typography variant="h5" gutterBottom>
                  External CRM Integration
                </Typography>
                {(!companySettings.crmIntegrationEnabled ||
                  !companySettings.crmFormTemplateId ||
                  !companySettings.crmCompanyID) ? (
                  <Button variant="contained" color="warning" onClick={handleOpenCrmModal}>
                    Connect CRM
                  </Button>
                ) : (
                  <Box>
                    <Alert severity="success" sx={{ mb: 2 }}>
                      Connected to {companySettings.crmProvider}!
                    </Alert>
                    <Typography variant="body2">
                      Form Template ID: {companySettings.crmFormTemplateId}
                    </Typography>
                    <Typography variant="body2">
                      Company ID: {companySettings.crmCompanyID}
                    </Typography>
                    <Button variant="contained" color="warning" sx={{ mt: 2 }} onClick={handleOpenCrmModal}>
                      Edit Connection
                    </Button>
                  </Box>
                )}
              </Paper>
            </TabPanel>
          )}
          <TabPanel value={activeTab} index={userRole === 'Owner' ? (TAB_VALUES as any).USER_SETTINGS : (TAB_VALUES as any).USER_SETTINGS}>
            <Paper elevation={3} sx={{ p: 4 }}>
              <Typography variant="h5" gutterBottom>
                User Settings
              </Typography>
              <Divider sx={{ mb: 3 }} />
              {userError && <Alert severity="error" sx={{ mb: 2 }}>{userError}</Alert>}
              {userSuccess && <Alert severity="success" sx={{ mb: 2 }}>{userSuccess}</Alert>}
              <form onSubmit={handleUserSubmit}>
                <TextField
                  label="Who I Am"
                  name="whoIAmText"
                  value={userSettings.whoIAmText}
                  onChange={handleUserChange}
                  margin="normal"
                  multiline
                  rows={4}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { borderColor: 'warning.main' } },
                    '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                  }}
                />
                <Button type="submit" variant="contained" color="warning" fullWidth={!isMobile} sx={{ mt: 2 }}>
                  Save User Settings
                </Button>
              </form>
              <PasswordChangeForm onSuccess={() => setUserSuccess('Settings updated successfully')} />
            </Paper>
          </TabPanel>
        </Box>
      </Box>

      {/* ---------------------- External CRM Integration Modal ---------------------- */}
      <Dialog open={crmModalOpen} onClose={handleCloseCrmModal} fullWidth maxWidth="sm">
        {crmModalStep === 0 && (
          <>
            <DialogTitle>Select Your External CRM</DialogTitle>
            <DialogContent dividers>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Choose the CRM you want to connect. This connection will automatically add new accounts when a customer accepts a cowboyquote.
              </Typography>
              <Grid container spacing={2}>
                {availableCRMs.map((crm) => (
                  <Grid item xs={6} key={crm.id}>
                    <Box
                      onClick={() => setSelectedCRM(crm.name)}
                      sx={{
                        p: 2,
                        borderRadius: 2,
                        border: selectedCRM === crm.name ? `2px solid ${theme.palette.warning.main}` : '1px solid #ccc',
                        cursor: 'pointer',
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="h6">{crm.name}</Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCrmModal} variant="contained" color="info">
                Cancel
              </Button>
              <Button onClick={handleCrmNext} disabled={!selectedCRM} variant="contained" color="warning">
                Next
              </Button>
            </DialogActions>
          </>
        )}
        {crmModalStep === 1 && (
          <>
            <DialogTitle>Enter CRM Integration Details</DialogTitle>
            <DialogContent dividers>
              <Typography variant="body1" sx={{ mb: 2 }}>
                For {selectedCRM}, please manually enter your <strong>Form Template ID</strong> and paste your <strong>Source Code</strong> (from which your Company ID will be parsed).
              </Typography>
              <TextField
                label="Form Template ID"
                fullWidth
                variant="outlined"
                value={modalFormTemplateId}
                onChange={(e) => setModalFormTemplateId(e.target.value)}
                sx={{ mb: 2,
                  '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { borderColor: 'warning.main' } },
                  '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                }}
              />
              <TextField
                label="Source Code"
                multiline
                rows={6}
                fullWidth
                variant="outlined"
                value={modalSourceCode}
                onChange={(e) => {
                  setModalSourceCode(e.target.value);
                  setParsingSuccess(false);
                }}
                sx={{
                  '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { borderColor: 'warning.main' } },
                  '& .MuiInputLabel-root.Mui-focused': { color: 'warning.main' },
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCrmPrevious} variant="contained" color="info">
                Previous
              </Button>
              <Button
                onClick={handleParseSnippet}
                disabled={!modalSourceCode.trim() || parsingInProgress}
                variant="contained"
                color="warning"
              >
                {parsingInProgress ? <MuiCircularProgress size={24} /> : 'Parse Company ID'}
              </Button>
              <Button
                onClick={handleCrmNext}
                disabled={!modalFormTemplateId.trim() || !parsingSuccess}
                variant="contained"
                color="warning"
              >
                Next
              </Button>
            </DialogActions>
          </>
        )}
        {crmModalStep === 2 && (
          <>
            <DialogTitle>Confirm Connection</DialogTitle>
            <DialogContent dividers>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Please review your connection details:
              </Typography>
              <Typography variant="body2">
                <strong>CRM:</strong> {selectedCRM}
              </Typography>
              <Typography variant="body2">
                <strong>Form Template ID:</strong> {modalFormTemplateId || 'Not found'}
              </Typography>
              <Typography variant="body2">
                <strong>Company ID:</strong> {modalParsedData.saCompanyID || 'Not found'}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCrmPrevious} variant="contained" color="info">
                Previous
              </Button>
              <Button onClick={handleConfirmCrmConnection} variant="contained" color="warning">
                Confirm Connection
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        message={companySuccess || ''}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default Settings;