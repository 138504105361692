// src/components/StreetViewPanorama.tsx
import React, { useEffect, useRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

interface StreetViewPanoramaProps {
  position: { lat: number; lng: number };
  apiKey: string;
}

const StreetViewPanorama: React.FC<StreetViewPanoramaProps> = ({ position, apiKey }) => {
  const panoramaRef = useRef<HTMLDivElement>(null);
  const streetViewRef = useRef<google.maps.StreetViewPanorama | null>(null);

  useEffect(() => {
    const loader = new Loader({
      apiKey: apiKey,
      version: 'weekly',
      libraries: ['places'],
    });

    loader.load().then(() => {
      if (panoramaRef.current) {
        streetViewRef.current = new google.maps.StreetViewPanorama(panoramaRef.current, {
          position,
          pov: { heading: 34, pitch: 10 },
          zoom: 1,
          addressControl: false,
          linksControl: true,
          fullscreenControl: false,
        });
      }
    });

    return () => {
      if (streetViewRef.current) {
        streetViewRef.current.unbindAll();
      }
    };
  }, [position, apiKey]);

  return <div ref={panoramaRef} style={{ height: '100%', width: '100%' }} />;
};

export default StreetViewPanorama;