// PredefinedServices.tsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Divider,
  Stack,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import axiosInstance from '../utils/axiosConfig';
import { useSnackbar } from 'notistack';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTheme } from '@mui/material/styles';

interface Service {
  _id: string;
  name: string;
  displayName: string;
  description: string;
  quotePageDesc: string;
  fineText: string;
}

// Define the Quill modules and formats
const quillModules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean'],
  ],
};

const quillFormats = ['bold', 'italic', 'underline', 'list', 'bullet'];

const PredefinedServices: React.FC = () => {
  const theme = useTheme();
  const [services, setServices] = useState<Service[]>([]);
  const [open, setOpen] = useState(false);
  const [editingService, setEditingService] = useState<Service>({
    _id: '',
    name: '',
    displayName: '',
    description: '',
    quotePageDesc: '',
    fineText: '',
  });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchServices();
    // eslint-disable-next-line
  }, []);

  const fetchServices = async () => {
    try {
      const response = await axiosInstance.get('/api/predefinedServices');
      setServices(response.data);
    } catch (error) {
      console.error('Error fetching predefined services:', error);
      enqueueSnackbar('Failed to fetch predefined services', { variant: 'error' });
    }
  };

  const handleOpen = (service: Service | null = null) => {
    if (service) {
      setEditingService(service);
    } else {
      setEditingService({
        _id: '',
        name: '',
        displayName: '',
        description: '',
        quotePageDesc: '',
        fineText: '',
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setEditingService({
      _id: '',
      name: '',
      displayName: '',
      description: '',
      quotePageDesc: '',
      fineText: '',
    });
    setOpen(false);
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const serviceData = {
      name: formData.get('name') as string,
      displayName: formData.get('displayName') as string,
      description: formData.get('description') as string,
      quotePageDesc: editingService.quotePageDesc.trim(),
      fineText: formData.get('fineText') as string,
    };

    try {
      if (editingService._id) {
        await axiosInstance.put(`/api/predefinedServices/${editingService._id}`, serviceData);
        enqueueSnackbar('Service updated successfully', { variant: 'success' });
      } else {
        await axiosInstance.post('/api/predefinedServices', serviceData);
        enqueueSnackbar('Service created successfully', { variant: 'success' });
      }
      fetchServices();
      handleClose();
    } catch (error) {
      console.error('Error saving predefined service:', error);
      enqueueSnackbar('Failed to save service', { variant: 'error' });
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await axiosInstance.delete(`/api/predefinedServices/${id}`);
      enqueueSnackbar('Service deleted successfully', { variant: 'success' });
      fetchServices();
    } catch (error) {
      console.error('Error deleting predefined service:', error);
      enqueueSnackbar('Failed to delete service', { variant: 'error' });
    }
  };

  const handleServiceChange = (field: keyof Service, value: string) => {
    setEditingService({
      ...editingService,
      [field]: field === 'quotePageDesc' ? value.trim() : value,
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 64px)',
        bgcolor: 'background.default',
        overflowY: 'auto',
      }}
    >
      <Box sx={{ width: '100%', p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Predefined Services
        </Typography>

        {/* Add New Service Button */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Button
            startIcon={<Add />}
            onClick={() => handleOpen()}
            variant="contained"
            color="warning"
          >
            Add New Service
          </Button>
        </Box>

        {/* Services List */}
        <Paper variant="outlined" sx={{ mb: 2 }}>
          <List>
            {services.map((service, index) => (
              <React.Fragment key={service._id}>
                <ListItem>
                  <ListItemText
                    primary={service.name}
                    secondary={service.description}
                    primaryTypographyProps={{ fontWeight: 'bold' }}
                    secondaryTypographyProps={{ whiteSpace: 'pre-line' }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => handleOpen(service)}
                      sx={{ mr: 1, width:40 }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" sx={{width:40, mr:2}} onClick={() => handleDelete(service._id)}>
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                {index < services.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Paper>

        {/* Dialog for Add/Edit Service */}
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          <DialogTitle>
            {editingService._id ? 'Edit Service' : 'Add New Service'}
          </DialogTitle>
          <form onSubmit={handleSave}>
            <DialogContent>
              <Stack spacing={2}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="name"
                  label="Service Name (Internal)"
                  type="text"
                  fullWidth
                  value={editingService.name}
                  onChange={(e) => handleServiceChange('name', e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: 'warning.main',
                      },
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: 'warning.main',
                    },
                  }}
                />

                <TextField
                  margin="dense"
                  name="displayName"
                  label="Display Name (Customer Facing)"
                  type="text"
                  fullWidth
                  value={editingService.displayName}
                  onChange={(e) => handleServiceChange('displayName', e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: 'warning.main',
                      },
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: 'warning.main',
                    },
                  }}
                />

                <TextField
                  margin="dense"
                  name="description"
                  label="Description"
                  type="text"
                  fullWidth
                  multiline
                  rows={2}
                  value={editingService.description}
                  onChange={(e) => handleServiceChange('description', e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: 'warning.main',
                      },
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: 'warning.main',
                    },
                  }}
                />

                <Box
                  sx={{
                    '& .ql-toolbar': {
                      backgroundColor: theme.palette.background.default,
                      borderColor: theme.palette.divider,
                      '& button, & .ql-picker': {
                        color: theme.palette.text.primary,
                      },
                      '& button:hover, & .ql-picker:hover': {
                        color: theme.palette.warning.main,
                      },
                      borderBottom: `1px solid ${theme.palette.divider}`,
                    },
                    '& .ql-container': {
                      backgroundColor: theme.palette.background.default,
                      color: theme.palette.text.primary,
                      borderColor: theme.palette.divider,
                      '& .ql-editor': {
                        minHeight: '150px',
                        '& .ql-placeholder': {
                          color: theme.palette.text.disabled,
                        },
                      },
                    },
                    '& .ql-toolbar, & .ql-container': {
                      borderRadius: theme.shape.borderRadius,
                    },
                  }}
                >
                  <Typography variant="subtitle1" gutterBottom>
                    Quote Page Description:
                  </Typography>
                  <ReactQuill
                    value={editingService.quotePageDesc}
                    onChange={(content: string) => handleServiceChange('quotePageDesc', content)}
                    modules={quillModules}
                    formats={quillFormats}
                  />
                </Box>

                <TextField
                  margin="dense"
                  name="fineText"
                  label="Fine Text"
                  type="text"
                  fullWidth
                  multiline
                  rows={3}
                  value={editingService.fineText}
                  onChange={(e) => handleServiceChange('fineText', e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: 'warning.main',
                      },
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: 'warning.main',
                    },
                  }}
                />
              </Stack>
            </DialogContent>

            <DialogActions sx={{ p: 2 }}>
              <Button
                onClick={handleClose}
                variant="contained"
                color="info"
                sx={{
                  mr: 1,
                  borderColor: 'info.main',
                  color: 'text',
                  '&:hover': {
                    borderColor: 'info.dark',
                    backgroundColor: 'info.light',
                  },
                }}
              >
                Cancel
              </Button>
              <Button type="submit" color="warning" variant="contained">
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Box>
    </Box>
  );
};

export default PredefinedServices;