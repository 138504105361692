import React, { useState, useEffect } from 'react';
import { Box, IconButton, Modal, Typography, Paper, Grid, Button, Slider, CircularProgress } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import { useSnackbar } from 'notistack';

interface Template {
  templateId: string;
  name: string;
  introParagraph: string;
  customServices: any[];
  predefinedServiceIds: string[];
  companyName: string;
  createdAt: string;
}

interface QuoteLimits {
  remainingQuotes: number;
  monthlyLimit: number;
  subscriptionStatus: string;
}

const CreateNew: React.FC = () => {
  // State management
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState<'initial' | 'templates' | 'generate'>('initial');
  const [templates, setTemplates] = useState<Template[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [quoteCount, setQuoteCount] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [quoteLimits, setQuoteLimits] = useState<QuoteLimits | null>(null);
  const [limitsLoading, setLimitsLoading] = useState(true);
  const userDataString = localStorage.getItem('userData');
  const user = userDataString ? JSON.parse(userDataString) : null;

  // Hooks
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // Fetch templates and quote limits on component mount
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        await Promise.all([
          fetchTemplates(),
          fetchQuoteLimits()
        ]);
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchInitialData();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await axiosInstance.get('/api/quoteTemplates');
      setTemplates(response.data);
    } catch (error) {
      console.error('Error fetching templates:', error);
      enqueueSnackbar('Failed to fetch templates', { variant: 'error' });
    }
  };

  const fetchQuoteLimits = async () => {
    setLimitsLoading(true);
    try {
      const response = await axiosInstance.get('/api/remaining-quotes');
      setQuoteLimits(response.data);
    } catch (error) {
      console.error('Error fetching quote limits:', error);
      enqueueSnackbar('Failed to fetch quote limits', { variant: 'error' });
    } finally {
      setLimitsLoading(false);
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
    setStep('initial');
    setSelectedTemplate(null);
    // Refresh quote limits when modal opens
    fetchQuoteLimits();
  };

  const handleClose = () => {
    setIsOpen(false);
    setStep('initial');
    setSelectedTemplate(null);
  };

  const handleQuotesClick = () => {
    if (templates.length === 0) return;
    setStep('templates');
  };

  const handleTemplateClick = () => {
    handleClose();
    navigate('/dashboard/templates');
  };

  const handleTemplateSelect = (templateId: string) => {
    setSelectedTemplate(templateId);
  };

  const handleBack = () => {
    if (step === 'generate') {
      setStep('templates');
    } else {
      setStep('initial');
    }
  };

  const handleProceed = () => {
    if (step === 'templates') {
      setStep('generate');
    }
  };

  const handleGenerateQuotes = async () => {
    if (!selectedTemplate) return;
    
    setLoading(true);
    try {
      const response = await axiosInstance.post('/api/quoteTemplates/generate-quotes', {
        templateId: selectedTemplate,
        count: quoteCount
      }, {
        responseType: 'blob'
      });

      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const downloadUrl = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = 'door_hangers.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Refresh quote limits after generation
      await fetchQuoteLimits();
      handleClose();
      navigate('/dashboard/all-quotes');
      enqueueSnackbar('Quotes generated successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error generating quotes:', error);
      enqueueSnackbar('Failed to generate quotes', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // Render loading state while fetching initial quote limits
  if (limitsLoading) {
    return (
      <IconButton disabled sx={{ position: 'fixed', right: 100, width: 60, height: 60, top: 20 }}>
        <CircularProgress size={24} />
      </IconButton>
    );
  }

  return (
    <>
    {user?.role !== 'User' && (
      <IconButton
        onClick={handleOpen}
        sx={{
          position: 'fixed',
          right: 100,
          top: 20,
          width: 60,
          height: 60,
          zIndex: 999,
          color: 'info',
          overflow: 'hidden',
          '&:hover': {
            bgcolor: 'warning.main',
          },
        }}
      >
        <Add sx={{ fontSize: 32 }} />
      </IconButton>
    )}
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="create-new-modal"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{ 
          width: step === 'templates' ? '80%' : '400px',
          maxWidth: '1200px',
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          overflow: 'auto'
        }}>
          {/* Initial Step */}
          {step === 'initial' && (
            <>
              <Typography variant="h6" gutterBottom>Create New</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Paper
                    onClick={handleQuotesClick}
                    sx={{
                      p: 3,
                      textAlign: 'center',
                      cursor: templates.length > 0 ? 'pointer' : 'not-allowed',
                      opacity: templates.length > 0 ? 1 : 0.5,
                      '&:hover': templates.length > 0 ? {
                        bgcolor: 'action.hover',
                        transform: 'scale(1.02)',
                      } : {},
                    }}
                  >
                    <Typography>Quotes</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    onClick={handleTemplateClick}
                    sx={{
                      p: 3,
                      textAlign: 'center',
                      cursor: 'pointer',
                      '&:hover': {
                        bgcolor: 'action.hover',
                        transform: 'scale(1.02)',
                      },
                    }}
                  >
                    <Typography>Template</Typography>
                  </Paper>
                </Grid>
              </Grid>
            </>
          )}

          {/* Templates Step */}
          {step === 'templates' && (
            <>
              <Typography variant="h6" gutterBottom>Select Template</Typography>
              <Grid container spacing={2}>
                {templates.map((template) => (
                  <Grid item xs={12} sm={6} md={4} key={template.templateId}>
                    <Paper
                      onClick={() => handleTemplateSelect(template.templateId)}
                      sx={{
                        p: 3,
                        cursor: 'pointer',
                        border: selectedTemplate === template.templateId ? 2 : 0,
                        borderColor: 'warning.main',
                        '&:hover': {
                          bgcolor: 'action.hover',
                        },
                      }}
                    >
                      <Typography variant="subtitle1">{template.name}</Typography>
                      <Typography variant="caption">
                        Created: {new Date(template.createdAt).toLocaleDateString()}
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={handleBack} color='info' variant='contained' sx={{mr:2}}>Back</Button>
                <Button
                  onClick={handleProceed}
                  disabled={!selectedTemplate}
                  variant="contained"
                  color="warning"
                >
                  Proceed
                </Button>
              </Box>
            </>
          )}

          {/* Generate Step */}
          {step === 'generate' && quoteLimits && (
            <>
              <Typography variant="h6" gutterBottom>Generate Quotes</Typography>
              <Typography gutterBottom>
                Select number of quotes to generate ({quoteLimits.remainingQuotes} remaining):
              </Typography>
              <Slider
                value={quoteCount}
                onChange={(_, newValue) => setQuoteCount(newValue as number)}
                valueLabelDisplay="auto"
                step={1}
                marks
                min={1}
                max={Math.min(100, quoteLimits.remainingQuotes)}
                color="warning"
              />
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={handleBack} color='info' variant='contained' sx={{mr:2}}>Back</Button>
                <Button
                  onClick={handleGenerateQuotes}
                  disabled={loading}
                  variant="contained"
                  color="warning"
                >
                  {loading ? <CircularProgress size={24} /> : 'Generate Quotes'}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default CreateNew;