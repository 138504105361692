// frontend/src/App.tsx

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Box, Button } from '@mui/material'; // Ensure Button is imported
import NavBar from './components/NavBar';
import Home from './components/Home';
import AdminLogin from './components/AdminLogin';
import AdminSetup from './components/AdminSetup';
import AdminDashboard from './components/AdminDashboard';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Logout from './components/Logout';
import Dashboard from './components/Dashboard';
import Settings from './components/Settings';
import ViewUser from './components/ViewUser';
import TrailGuide from './components/TrailGuide/TrailGuide';
import QuoteTemplatePage from './components/QuoteTemplatePage';
import ViewQuote from './components/ViewQuote';
import TheRoundup from './components/TheRoundup';
import CampaignsList from './components/campaigns/CampaignsList';
import CampaignDetails from './components/campaigns/CampaignDetails';
import EmailVerification from './components/EmailVerification';
import ResendVerification from './components/ResendVerification';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import SubscriptionManager from './components/SubscriptionManager';
import PredefinedServicesPage from './components/PredefinedServicesPage';
import ViewAllQuotes from './components/ViewAllQuotes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import '@fontsource/poppins';
import './App.css';
import CustomerQuoteLandingPage from './components/CustomerQuoteLanding';
import ViewCampaign from './components/campaigns/ViewCampaign';
import QuotaIndicator from './components/QuotaIndicator';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useAuth } from './AuthContext'; // Ensure useAuth is imported
import { RemainingQuotesProvider } from './RemainingQuotesContext';
import ProtectedRoute from './components/ProtectedRoute';
import AdminProtectedRoute from './components/AdminProtectedRoute';
import AdminLogout from './components/AdminLogout';
import NotFound from './components/NotFound';
import DebugMenu from './components/DebugMenu';


const DEBUG_MODE = process.env.NODE_ENV === 'development';

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

const App: React.FC = () => {
  const [mode, setMode] = useState<'light' | 'dark'>('light');
  
  // Destructure isImpersonating and setIsImpersonating from useAuth
  const { isAuthenticated, isAdmin, isImpersonating, setIsImpersonating } = useAuth();

  useEffect(() => {
    const savedMode = localStorage.getItem('colorMode');
    if (savedMode) {
      setMode(savedMode as 'light' | 'dark');
    }
  }, []);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === 'light' ? 'dark' : 'light';
          localStorage.setItem('colorMode', newMode);
          return newMode;
        });
      },
    }),
    [],
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: mode === 'light' ? '#052F46' : '#312722',
          },
          secondary: {
            main: '#C6623A',
          },
          info: {
            main: mode === 'light' ? '#064454' : '#00647A',
          },
          background: {
            default: mode === 'light' ? '#E7E7E7' : '#1E1F20',
            paper: mode === 'light' ? '#ffffff' : '#041821',
          },
        },
        typography: {
          fontFamily: 'Poppins, Arial, sans-serif',
        },
      }),
    [mode],
  );

  const ConditionalNavBar = () => {
    const location = useLocation();
    const hideNavbarPaths = ['/dashboard', '/quote', '/admin'];
  
    const shouldHideNavbar = hideNavbarPaths.some((path) =>
      location.pathname.startsWith(path)
    );
  
    // Add this useEffect to handle the class
    useEffect(() => {
      const root = document.getElementById('root');
      if (root) {
        if (!shouldHideNavbar) {
          root.classList.add('has-navbar');
        } else {
          root.classList.remove('has-navbar');
        }
      }
    }, [shouldHideNavbar]);
  
    return !shouldHideNavbar ? <NavBar /> : null;
  };

  // Determine if the user is a regular authenticated user (not an admin)
  const isRegularUser = isAuthenticated === true && isAdmin === false;

  // Function to handle exiting impersonation mode
  const handleExitImpersonation = () => {
    localStorage.removeItem('impersonationToken');
    setIsImpersonating(false);
    window.location.href = '/admin/dashboard'; // Adjust the path if necessary
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline />
          <SnackbarProvider
  maxSnack={3}
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'right', // Base alignment remains right
  }}
  SnackbarProps={{
    style: { 
      zIndex: 11000,
      // Adjust this value to control how far from the right edge
      right: "100px" // Moves 100px left from the right corner
    }
  }}
>
            <Router>
              <ConditionalNavBar />

              {/* Exit Impersonation Banner */}
              {isImpersonating && (
                <Box bgcolor="warning.main" color="white" p={1} textAlign="center">
                  You are currently impersonating a company.{' '}
                  <Button color="warning" onClick={handleExitImpersonation} sx={{width:250, bgcolor:'primary.main', ml:5}}>
                    Exit Impersonation
                  </Button>
                </Box>
              )}

              {/* Show QuotaIndicator only for regular authenticated users */}
              {isRegularUser && (
                <RemainingQuotesProvider>
                  <QuotaIndicator />
                </RemainingQuotesProvider>
              )}

              <Routes>
                {/* Public Routes */}
                <Route path="/" element={<Home />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/admin/login" element={<AdminLogin />} />
                <Route path="/verify-email" element={<EmailVerification />} />
                <Route path="/resend-verification" element={<ResendVerification />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/view-campaign/:campaignId" element={<ViewCampaign />} />
                <Route
                  path="/quote/:quoteId/:companyName"
                  element={<CustomerQuoteLandingPage />}
                />

                {/* Protected User Routes */}
                <Route
                  path="/dashboard/*"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <ProtectedRoute>
                      <Settings />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/trail-guide"
                  element={
                    <ProtectedRoute>
                      <TrailGuide />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/quote-template/:templateId"
                  element={
                    <ProtectedRoute>
                      <QuoteTemplatePage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/all-quotes"
                  element={
                    <ProtectedRoute>
                      <ViewAllQuotes />
                    </ProtectedRoute>
                  }
                />
                
                <Route
                  path="/internal/quote/:quoteId"
                  element={
                    <ProtectedRoute>
                      <ViewQuote />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/predefined-services"
                  element={
                    <ProtectedRoute>
                      <PredefinedServicesPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/subscription"
                  element={
                    <ProtectedRoute>
                      <SubscriptionManager />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/theroundup"
                  element={
                    <ProtectedRoute>
                      <TheRoundup />
                    </ProtectedRoute>
                  }
                />
                 <Route
                  path="/campaigns"
                  element={
                    <ProtectedRoute>
                      <CampaignsList />
                    </ProtectedRoute>
                  }
                />
                 <Route
                  path="/campaigns/:campaignId"
                  element={
                    <ProtectedRoute>
                      <CampaignDetails />
                    </ProtectedRoute>
                  }
                />
                <Route 
                path="users/:userId"
                 element={
                  <ProtectedRoute>
                 <ViewUser />
                 </ProtectedRoute>
                 } />

                {/* Admin Routes */}
                <Route
                  path="/admin/dashboard/*"
                  element={
                    <AdminProtectedRoute>
                      <AdminDashboard />
                    </AdminProtectedRoute>
                  }
                />
                <Route path="/admin/logout" element={<AdminLogout />} />

                {process.env.NODE_ENV === 'development' && (
                  <Route path="/admin/setup" element={<AdminSetup />} />
                )}

                {/* Fallback Route */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            {/*   {DEBUG_MODE && <DebugMenu />} */}
            </Router>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;